<template>
  <div class="app-body">
    <app-sidebar type="h5"></app-sidebar>
    <div class="app-main">
      <app-toolbar
        v-on:showPageSet="showPageSet"
        v-on:savePageSet="savePageSet"
        v-on:showPreview="showPreview"
      ></app-toolbar>
      <div class="scroll-y" style="">
        <!-- @dragover.self="dragPhoneOver" @drop.self="dropPhone" -->
        <div
          class="app-phone"
          @dragleave.self="dragPhoneLeave"
          @dragover.prevent
          :style="{ backgroundColor: pageConfig.base[2].val }"
        >
          <template v-for="(comp, idx) in compList">
            <!--占位提示控件-->
            <div
              v-if="comp.type === 'placeholder'"
              class="place-holder"
              @dragover.stop="dragover"
              @dragleave.stop="dragleave"
              @drop.stop="drop"
              @dragover.prevent
              :key="'place-' + idx"
              :data-idx="idx"
            >
              放在这里
            </div>

            <div
              v-else
              :class="['tpl-container', comp.active ? 'current' : '']"
              :key="'tpl-' + idx"
              :data-idx="idx"
              @click.capture="clickComp"
            >
              <!--图片控件-->
              <base-image v-if="comp.type === 'img'" :component="comp"></base-image>
              <!--轮播图控件-->
              <app-swiper v-if="comp.type === 'app-swiper'" :component="comp"></app-swiper>
              <kuran-split-image
                v-if="comp.type === 'split-image'"
                :component="comp"
              ></kuran-split-image>
              <kuran-paragraph
                v-if="comp.type === 'kuran-paragraph'"
                :component="comp"
              ></kuran-paragraph>
              <kuran-goods-fullwidth
                v-if="comp.type === 'kuran-goods-fullwidth'"
                :component="comp"
                :compList="compList"
                :option="pageConfig"
                @sutmitTocompList="sutmitTocompList"
              ></kuran-goods-fullwidth>
              <kuran-title v-if="comp.type === 'kuran-title'" :component="comp"></kuran-title>
              <kuran-form v-if="comp.type === 'kuran-form'" :component="comp"></kuran-form>
              <kuran-floor-menu
                v-if="comp.type === 'kuran-floor-menu'"
                :component="comp"
              ></kuran-floor-menu>
              <kuran-swiper v-if="comp.type === 'kuran-swiper'" :component="comp"></kuran-swiper>
              <king-kong v-if="comp.type === 'king-kong'" :component="comp"></king-kong>
              <v-goods-bang
                v-if="comp.type === 'v-goods-bang'"
                :isInHome="false"
                :component="comp"
              ></v-goods-bang>

              <!--控件操作-->
              <div class="comp-menu">
                <a
                  href="javascript:void(0)"
                  :class="[idx == 1 ? 'disabled' : '']"
                  @click="upComp(idx)"
                  v-if="comp.type !== 'recommendGoods'"
                >
                  <span class="tips">上移</span>
                  <i class="fa fa-arrow-circle-up"></i>
                </a>
                <a
                  href="javascript:void(0)"
                  :class="[idx == compList.length - 2 ? 'disabled' : '']"
                  @click="downComp(idx)"
                  v-if="comp.type !== 'recommendGoods' && idx !== compList.length - 3"
                >
                  <span class="tips">下移</span>
                  <i class="fa fa-arrow-circle-down"></i>
                </a>
                <a href="javascript:void(0)" @click="delComp(idx)" v-if="!isDefaultComponents">
                  <span class="tips">删除</span>
                  <i class="fa fa-trash"></i>
                </a>
              </div>
            </div>
          </template>
        </div>

        <!--底部导航控件-->
        <div
          v-if="bottomMenu"
          :class="['absolute-tpl', bottomMenu.active ? 'current' : '']"
          @click="clickBtmMenu"
        >
          <bottom-menu :component="bottomMenu"></bottom-menu>
          <div class="comp-menu">
            <a href="javascript:void(0)" @click="delBtmMenu">
              <span class="tips">删除</span>
              <i class="fa fa-trash"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <preview-dialog
      :show.sync="previewShow"
      :previewUrl="previewUrl"
      :compList="compList"
    ></preview-dialog>
    <app-opt
      ref="appOption"
      v-if="currentConfig"
      :option="currentConfig"
      :pageConfig="pageConfig"
    ></app-opt>
    <app-page-opt v-else :option="pageConfig"></app-page-opt>
    <click-config
      :show.sync="clickShow"
      :option="currentConfig"
      :comps="compList"
      :index="click.index"
      :tabs="click.tabs"
    ></click-config>
  </div>
</template>

<script>
import util from '@/utils/h5-marker-util.js';
import appSidebar from '@/components/h5maker/views/layout/sidebar.vue';
import appToolbar from '@/components/h5maker/views/layout/toolbar.vue';
// import appOpt from "@/components/h5maker/views/layout/option.vue";
import appOpt from '@/components/h5maker/views/layout/h5.option.vue';
import appPageOpt from '@/components/h5maker/views/layout/pageOption.vue';

import clickConfig from '@/components/h5maker/kuran/click.vue';
import previewDialog from '@/components/h5maker/common/preview.vue';
// 页面默认配置
import pageOption from '@/config/h5.page.config.js';
// 组件默认配置
import compConfig from '@/config/h5.comp.config.js';
import { getTopics, createTopic, patchTopic, previewTopic } from '@/service/topic';
import { checkPer } from '@/utils/index';

import { setEditStatus } from '@/utils/index.js';
import { showLoading, hideLoading } from '@/components/common/loading/loading.js';

export default {
  name: 'AppHomeMain',
  components: {
    appSidebar,
    appToolbar,
    appOpt,
    appPageOpt,
    clickConfig,
    previewDialog,
  },
  data() {
    return {
      topicId: this.$route.query.id,
      isPageLoaded: false,
      clickShow: false,
      previewShow: false,
      tempData: null, // 临时数据
      backendData: null, // 已保存数据
      click: {
        index: 0,
        tabs: [],
      },
      compList: [
        {
          type: 'placeholder',
        },
      ],
      bottomMenu: null,
      kuruanForm: null,
      pageConfig: util.copyObj(pageOption),
      currentIndex: -1,
      currentConfig: null,
      previewUrl: '',
    };
  },
  created() {
    this.$store.commit('setGettingGoodsStatus', false); //全局设置
    if (this.topicId) {
      this.getTopicConfigList();
    }
  },
  mounted() {
    setEditStatus('false');
    window.onbeforeunload = function (e) {
      e = e || window.event;
      // 兼容IE8和Firefox 4之前的版本
      if (e) {
        e.returnValue = '关闭提示';
      }
      return '关闭提示';
    };

    this.$bus.$on('click:show', (idx, tabs) => {
      this.click.index = idx;
      if (Array.isArray(tabs) && tabs.length) this.click.tabs = tabs;
      else this.click.tabs = ['outside', 'page', 'tel'];
      this.clickShow = true;
    });

    this.$bus.$on('click:submit', (idx, config) => {
      if (idx > -1 && config) {
        if (this.currentIndex >= 0) {
          this.compList[this.currentIndex].action.config[idx].click = config;
        } else if (this.currentIndex === -2) {
          // 底部导航栏点击配置
          this.bottomMenu.action.config[idx].click = config;
        }
      }
    });

    this.showPageSet();
  },
  computed: {
    isDefaultComponents: function () {
      const _type = this.currentConfig && this.currentConfig['type'];
      const isDefault = [
        'douyinHotSale',
        'hotSale',
        'highDiscount',
        'highCommission',
        'recommendGoods',
      ].includes(_type);
      return isDefault;
    },
  },
  watch: {
    compList: {
      handler(val) {
        if (val && val.length > 1) {
          localStorage.setItem(
            'pageDateSet',
            JSON.stringify({
              time: Date.now(),
              menu: this.bottomMenu,
              config: val,
            }),
          );
          // 修复未改变布局 点击一次就组件就会触发改变，主要原因就是active激活状态属性导致，所以剔除这个属性进行比较
          // const strVal = JSON.stringify(val);
          // const strBackendData = JSON.stringify(this.backendData);
          let strVal = JSON.parse(JSON.stringify(val));
          strVal = JSON.stringify(
            strVal.map(item => {
              item.active = undefined;
              return item;
            }),
          );
          let strBackendDataOri = this.backendData
            ? JSON.parse(JSON.stringify(this.backendData))
            : [];
          const strBackendData = JSON.stringify(
            strBackendDataOri.map(item => {
              item.active = undefined;
              return item;
            }),
          );

          if (strVal === strBackendData) {
            setEditStatus('false');
          } else {
            setEditStatus('true');
          }
        }
      },
      deep: true,
    },
    bottomMenu: {
      handler(val) {
        localStorage.setItem(
          'pageDateSet',
          JSON.stringify({
            time: Date.now(),
            menu: val,
            config: this.compList,
          }),
        );
      },
    },
  },
  // 页面卸载
  destroyed() {
    window.onbeforeunload = null;
  },
  methods: {
    sutmitTocompList(arr) {
      this.compList = arr;
    },
    // 获取配置信息
    async getTopicConfigList() {
      if (!checkPer('operate_manage_modify_custom')) {
        this.$message.error('无权限获取数据');
        return false;
      }
      const params = {
        id: this.$route.query.id,
        extra_fields: 'backend_settings',
      };
      this.listLoading = true;
      const { err, res } = await getTopics(params);
      this.listLoading = false;
      this.isPageLoaded = true;
      if (!err) {
        if (res.success && res.data.count) {
          const backendData = JSON.parse(JSON.stringify(res.data.results[0].backend_settings));
          this.compList = backendData;
          this.backendData = JSON.parse(JSON.stringify(backendData));
          this.tempData = JSON.parse(JSON.stringify(backendData));
          this.pageConfig.base[0].val = res.data.results[0].page_title;
          this.pageConfig.base[1].val = res.data.results[0].tag;
          // 页面背景颜色
          const lastConfig =
            res.data.results[0].backend_settings[res.data.results[0].backend_settings.length - 1];
          if (lastConfig && lastConfig.type == 'page-config') {
            lastConfig.base.forEach(item => {
              if (item.attr === 'background-color') {
                this.pageConfig.base[2].val = item.val;
              } else if (item.attr === 'page-tag' && item.val) {
                this.pageConfig.base[1].val = item.val;
              }
            });
          }
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    showPageSet() {
      this.resetCompUnchecked();
      this.currentIndex = -1;
      this.currentConfig = null;
    },
    // 判断当前项是否有没有填写到
    checkCurrentBlock() {
      let isAllPassed = true;
      if (this.currentConfig !== null) {
        if (!this.isDefaultComponents) {
          isAllPassed = this.currentConfig.action['config'].every(function (i) {
            let jumpCheck = false;
            if (i.type === 'none') {
              jumpCheck = true;
              return jumpCheck;
            } else {
              jumpCheck = i.value;
            }
            return jumpCheck && i.width && i.imgUrl;
          });
        } else {
          isAllPassed = true;
        }
      } else {
        isAllPassed = true;
      }
      return isAllPassed;
    },
    // 检测必填项
    async checkIsAllFill() {
      if (!this.currentConfig) return true;
      let res = false;
      try {
        res = await this.$refs.appOption.validateAllOptions();
      } catch (error) {
        console.log(error);
        res = false;
      }
      return res;
    },

    /**
     * @description 检测某些组件的数量
     * @param {String} compType 当前被拖入的组件type
     */
    checkComponentsCount(compType) {
      if (this.compList && this.compList.length > 0) {
        // 判断导航数量
        if (compType === 'kuran-floor-menu') {
          const floorMenu = this.compList.filter(item => item.type == 'kuran-floor-menu');
          if (floorMenu.length >= 1) {
            this.$message.error('导航栏组件只能添加一个');
            return false;
          }
        }
        // 判断表单数量
        if (compType === 'kuran-form') {
          const kuranForm = this.compList.filter(item => item.type == 'kuran-form');
          if (kuranForm.length >= 1) {
            this.$message.error('报名表单组件只能添加一个');
            return false;
          }
        }
      }
      return true;
    },
    // 判断是否有errorMsg
    hasErrorMsg() {
      if (this.currentConfig) {
        const currentBlock = JSON.parse(JSON.stringify(this.currentConfig));
        if (
          ['app-swiper', 'split-image', 'king-kong', 'v-goods-bang'].includes(currentBlock.type)
        ) {
          const hasError = currentBlock.action.config.some(
            i => i.hasOwnProperty('errorMsg') && i.errorMsg !== null,
          );
          return hasError;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // 验证图片数量
    hasImgNumError() {
      if (this.currentConfig) {
        if (
          this.currentConfig.type === 'split-image' ||
          this.currentConfig.type === 'kuran-swiper'
        ) {
          if (this.currentConfig && this.currentConfig.base) {
            const split = this.currentConfig.base[0]['val'];
            const items = this.currentConfig.action && this.currentConfig.action.config;
            // 图片个数
            const imgsCount = items.reduce(function (prev, current) {
              if (current.imgUrl.length) {
                return prev + 1;
              } else {
                return prev;
              }
            }, 0);

            if (imgsCount % split !== 0) {
              return true;
            }
          }
        }
      }
      return false;
    },
    // 获取deadline-time
    getDeadlineTime(itemsData) {
      const deadlineTimeIdx = itemsData.findIndex(i => i.type === 'kuran-form');
      if (deadlineTimeIdx > -1) {
        let deadlineTime = itemsData[deadlineTimeIdx]['base'][0]['val'];
        if (deadlineTime) {
          const timeArr = deadlineTime.split(':');
          deadlineTime = timeArr[0] + ':' + timeArr[1];
        }
        return deadlineTime;
      } else {
        return null;
      }
    },
    // 验证外露表单是否有字段没有填写
    checkIfVGoodsBangFilled() {
      if (
        this.currentConfig &&
        this.currentConfig.base &&
        this.currentConfig.type === 'v-goods-bang'
      ) {
        const title = this.currentConfig.base[0]['val'];
        const desc = this.currentConfig.base[1]['val'];
        if (title.length === 0 || desc.length === 0) {
          return true;
        }
      }
      return false;
    },
    // 验证金刚位数量是否超过标准
    checkIfKingKongOver() {
      if (
        this.currentConfig &&
        this.currentConfig.base &&
        this.currentConfig.type === 'king-kong'
      ) {
        const split = this.currentConfig.base[0]['val'];
        const items = this.currentConfig.action.config;
        if (split === 4 && items.length > 8) {
          return true;
        }
      }
      return false;
    },
    // 保存页面数据
    async savePageSet() {
      // 判断组件是否完成
      const res = await this.checkIsAllFill();
      if (!res) {
        this.$message.error('当前模块还有未完善的内容，请先完善后再操作其他模块！');
        return false;
      }

      if (this.checkIfVGoodsBangFilled()) {
        this.$message.error('标题文案和描述文案为必填项！');
        return false;
      }

      const pageTitle = this.pageConfig.base[0].val;
      const pageConfig = this.pageConfig;
      if (!pageTitle) {
        this.$message.error('请填写页面标题！');
        return false;
      }

      if (this.hasImgNumError()) {
        this.$message.error('图片数量须为等分图的倍数！');
        return false;
      }

      // 检查金刚位的个数是否超标
      if (this.checkIfKingKongOver()) {
        this.$message.error(
          '金刚位图片数量超标，单排4个时，图片最多8个，单排5个时，图片最多10个！',
        );
        return false;
      }

      const setting = JSON.parse(JSON.stringify(this.compList));
      pageConfig.type = 'page-config';
      // 将页面配置信息插入到最后
      if (setting[setting.length - 1].type == 'page-config') {
        setting[setting.length - 1] = pageConfig;
      } else {
        setting.push(pageConfig);
      }

      let formMapKey = [];
      setting.forEach(i => {
        i.active = false;
        if (i.type === 'kuran-form') {
          formMapKey = i.action.config.map(j => ({
            desc: j.desc,
            name: j.name,
          }));
        }
        return i;
      });
      // 获取结束时间
      const apply_deadline_time = this.getDeadlineTime(setting);
      if (apply_deadline_time === '') {
        this.$message.error('请填写报名截止时间！');
        return false;
      }
      showLoading('正在保存中，请稍等！');
      const data = {
        page_title: pageTitle,
        tag: this.pageConfig.base[1].val || '',
        page_url: '',
        apply_fields: JSON.stringify(formMapKey),
        apply_deadline_time,
        // backend_settings: JSON.stringify(this.backend), // 重置
        backend_settings: JSON.stringify(setting),
        frontend_settings: '[]',
        id: this.topicId,
      };
      if (this.topicId) {
        //  更新专题
        const { err, res } = await patchTopic(data);
        hideLoading();
        if (!err) {
          if (res.success) {
            this.tempData = this.compList;
            this.backendData = JSON.parse(JSON.stringify(this.compList));
            this.$message({
              message: '保存成功',
              type: 'success',
            });
            setEditStatus('false');
          }
        }
      } else {
        //  新建专题
        const { err, res } = await createTopic(data);
        hideLoading();
        if (!err) {
          if (res.success) {
            this.tempData = this.compList;
            this.backendData = JSON.parse(JSON.stringify(this.compList));
            this.topicId = res.data.id;
            this.$message({
              message: '保存成功',
              type: 'success',
            });
            setEditStatus('false');
          }
        }
      }
    },
    // 后台图片数据转前台图片数据
    exSplitImageDataToFront(data) {
      let res = {
        type: 'split-image',
        width: data.action.config[0]['width'],
        height: data.action.config[0]['height'],
        split: data.base[0].val,
        items: data.action.config,
      };
      return res;
    },
    // 后台swiper数据转前台数据
    exSwiperDataToFront(data) {
      let res = {
        type: 'app-swiper',
        width: data.action.config[0]['width'],
        height: data.action.config[0]['height'],
        items: data.action.config,
      };
      return res;
    },
    // 显示预览
    async showPreview() {
      const pageTitle = this.pageConfig.base[0].val;

      if (!pageTitle) {
        this.$message.error('请填写页面标题！');
        return false;
      }
      const setting = JSON.parse(JSON.stringify(this.compList));

      // 将页面配置信息插入到最后
      if (setting[setting.length - 1].type == 'page-config') {
        setting[setting.length - 1] = this.pageConfig;
      } else {
        setting.push(this.pageConfig);
      }

      // 获取结束时间
      const apply_deadline_time = this.getDeadlineTime(setting);
      if (apply_deadline_time === '') {
        this.$message.error('请填写报名截止时间！');
        return false;
      }

      const data = {
        page_title: pageTitle,
        page_url: '',
        apply_fields: '[]',
        apply_deadline_time: apply_deadline_time,
        backend_settings: JSON.stringify(setting),
        frontend_settings: '[]',
        id: this.$route.query.id || 520520,
        uid: '70803c1acb1ee113c07ec6bd88888888',
      };

      const { err, res } = await previewTopic(data);
      if (!err) {
        if (res.success && res.data && res.data.uid) {
          this.previewUrl =
            'https://static.zheyi360.com/tempTopic/topic_' + res.data.uid + '.html?inPreview=1';
        }
      }

      this.previewShow = true;
    },
    resetCompUnchecked() {
      if (this.bottomMenu) this.bottomMenu.active = false;
      this.compList.forEach(val => {
        if (val.active) {
          val.active = false;
        }
      });
    },
    replacePlaceholderWithComp(index, key) {
      const comp = util.copyObj(compConfig[key]);
      const config = {
        type: key,
        active: true,
        domId: key + '-' + util.createDomID(),
      };
      Object.assign(comp, config);
      this.compList.splice(index + 1, 0, comp);
      // 再插入一个占位控件
      this.compList.splice(index + 2, 0, {
        type: 'placeholder',
      });
      // 显示配置项
      this.currentIndex = index + 1;
      this.currentConfig = comp;
    },
    addBottomMenu() {
      const comp = util.copyObj(compConfig['bottom-menu']);
      const config = {
        type: 'bottom-menu',
        active: true,
        domId: 'bottom-menu-' + util.createDomID(),
      };
      Object.assign(comp, config);
      this.bottomMenu = comp;
      // 显示配置项
      this.currentIndex = -1;
      this.currentConfig = comp;
    },
    // 选择块
    async clickComp(e) {
      if (this.$store.state.app.isGettingGoods) {
        this.$message.error('正在请求商品数据，请等待完成获后再切换！');
        return false;
      }
      if (this.bottomMenu) this.bottomMenu.active = false;
      const idx = parseInt(e.currentTarget['dataset']['idx']); // 点击模块index
      const _idx = this.compList.findIndex(i => i.active === true); // 选中模块index
      if (idx !== _idx) {
        const res = await this.checkIsAllFill();
        if (!res) {
          this.$message.error('当前模块还有未完善的内容，请先完善后再操作其他模块！');
          return false;
        }

        if (this.checkIfVGoodsBangFilled()) {
          this.$message.error('标题文案和描述文案为必填项！');
          return false;
        }

        if (this.hasImgNumError()) {
          this.$message.error('图片数量须为等分图的倍数！');
          return false;
        }

        // // 判断区块是否有没填写的字段
        // if (!this.checkCurrentBlock()) {
        //   this.$message.error(
        //     "当前模块还有未完善的内容，请先完善后再操作其他模块！"
        //   );
        //   return false;
        // }
        if (this.hasImgNumError()) {
          this.$message.error('图片数量须为等分图的倍数！');
          return false;
        }

        // 检查金刚位的个数是否超标
        if (this.checkIfKingKongOver()) {
          this.$message.error(
            '金刚位图片数量超标，单排4个时，图片最多8个，单排5个时，图片最多10个！',
          );
          return false;
        }
      }

      this.compList.forEach((val, index) => {
        if (index === idx) {
          val.active = true;
          this.currentIndex = index;
          this.currentConfig = val;
        } else {
          val.active = false;
        }
      });
    },
    clickBtmMenu() {
      this.resetCompUnchecked();
      if (this.bottomMenu) this.bottomMenu.active = true;
      this.currentIndex = -2;
      this.currentConfig = this.bottomMenu;
    },
    delBtmMenu() {
      this.bottomMenu = null;
      // 显示页面配置参数
      this.showPageSet();
    },
    upComp(idx) {
      console.log('upComp');
      if (idx < 2) {
        return false;
      }
      // 复制当前控件
      const tmp = util.copyObj(this.compList[idx]);
      // 再删除控件+占位坑
      this.compList.splice(idx, 2);
      // 再插入控件
      this.compList.splice(idx - 2, 0, tmp);
      // 最后插入一个占位控件
      this.compList.splice(idx - 1, 0, {
        type: 'placeholder',
      });
      // 显示当前组件配置
      this.currentIndex = idx - 2;
      this.currentConfig = this.compList[idx - 2];
      setEditStatus('true');
    },
    downComp(idx) {
      if (idx === this.compList.length - 2) {
        return false;
      }
      // 复制当前控件
      const tmp = util.copyObj(this.compList[idx]);
      // 再删除控件+占位坑
      this.compList.splice(idx, 2);
      // 再插入控件
      this.compList.splice(idx + 2, 0, tmp);
      // 最后插入一个占位控件
      this.compList.splice(idx + 3, 0, {
        type: 'placeholder',
        active: false,
      });
      // 显示当前组件配置
      this.currentIndex = idx + 2;
      this.currentConfig = this.compList[idx + 2];
      setEditStatus('true');
    },
    delComp(idx) {
      console.log('delComp');
      // 删除控件
      this.compList.splice(idx, 2);
      // 显示页面配置参数
      this.showPageSet();
      setEditStatus('true');
    },
    // 放到placeholder上
    dragover(e) {
      const target = e.target;
      if (!target.classList.contains('active')) target.classList.add('active');
    },
    async drop(e) {
      const target = e.target;
      target.classList.remove('active');

      // 判断区块是否有没填写的字段
      const res = await this.checkIsAllFill();
      if (!res) {
        this.$message.error('当前模块还有未完善的内容，请先完善后再操作其他模块！');
        return false;
      }

      if (this.hasImgNumError()) {
        this.$message.error('图片数量须为等分图的倍数！');
        return false;
      }

      const key = e.dataTransfer.getData('cmp-type');
      if (key === 'bottom-menu') return;

      // 检测组件数量
      const pass = this.checkComponentsCount(key);
      if (!pass) return false;

      const idx = parseInt(target.dataset.idx);
      if (compConfig[key]) {
        this.resetCompUnchecked();
        this.replacePlaceholderWithComp(idx, key);
      } else {
        this.$message.warning('没有查询到该组件的配置信息。。。');
      }
    },
    // 从app编辑器移出来
    dragleave(e) {
      e.target.classList.remove('active');
    },
    // drop到app编辑区
    dragPhoneOver() {
      const target = document.querySelector('.place-holder:last-child');
      if (target && !target.classList.contains('active')) target.classList.add('active');
    },
    // 在app编辑区放下组件
    dropPhone(e) {
      const target = document.querySelector('.place-holder:last-child');
      if (target) {
        target.classList.remove('active');
        const key = e.dataTransfer.getData('cmp-type');
        const idx = parseInt(target.dataset.idx);
        if (compConfig[key]) {
          if (key === 'bottom-menu') {
            if (this.bottomMenu) {
              this.$message.info('已经存在一个底部导航组件了，请勿重复添加！');
            } else {
              this.addBottomMenu();
            }
          } else if (key === 'kuran-form') {
            // 添加表单
            if (this.kuruanForm) {
              this.$message.info('已经存在一个报名表单了，请勿重复添加！');
              return false;
            } else {
              this.resetCompUnchecked();
              this.replacePlaceholderWithComp(idx, key);
            }
          } else {
            this.resetCompUnchecked();
            this.replacePlaceholderWithComp(idx, key);
            setEditStatus('true');
          }
        } else {
          this.$message.warning('没有查询到该组件的配置信息。。。');
        }
      }
    },
    // 在app编辑区放下将组件
    dragPhoneLeave() {
      const target = document.querySelector('.place-holder:last-child');
      target && target.classList.remove('active');
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
@font-face {
  font-family: 'iconfont'; /* project id 1818723 */
  src: url('//at.alicdn.com/t/font_1818723_8hl8rmj697c.eot');
  src: url('//at.alicdn.com/t/font_1818723_8hl8rmj697c.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_1818723_8hl8rmj697c.woff2') format('woff2'),
    url('//at.alicdn.com/t/font_1818723_8hl8rmj697c.woff') format('woff'),
    url('//at.alicdn.com/t/font_1818723_8hl8rmj697c.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_1818723_8hl8rmj697c.svg#iconfont') format('svg');
}
.app-home-d-block {
  text-align: center;
  padding: 0 15px;
  .default-c-box {
    img {
      display: block;
      width: 100%;
    }
  }
}
.app-main {
  position: relative;
  flex: 1;
  min-width: 377px;
  background-color: #ffffff;
  .scroll-y {
    position: absolute;
    top: 40px;
    bottom: 0;
    width: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-y: auto;
    background-color: #f5f5f5;
  }

  .app-phone {
    position: relative;
    box-sizing: border-box;
    width: 377px;
    min-height: 667px;
    padding-bottom: 200px;
    margin: 0 auto;
    background-color: #f4f6f6;

    .place-holder {
      position: relative;
      display: block;
      height: 10px;
      text-align: center;
      font-size: 0;
      background: #fff;
      background-image: linear-gradient(#fff 2px, transparent 0),
        linear-gradient(90deg, #999 1px, transparent 0);
      background-size: 3px 3px, 3px 3px;

      &.active {
        height: 40px;
        line-height: 40px;
        border: 1px dashed #f32e37;
        background-color: #ffe800;
        background-image: none;
        color: #333;
        font-size: 14px;
        margin: 0;
      }
    }

    .tpl-container {
      position: relative;
      width: 100%;
      background-color: transparent;
      position: relative;
      border: 1px solid #f2f3f4;
      transition: opacity 0.1s ease-out;
      user-select: none;
      box-sizing: border-box;
      z-index: auto;
      opacity: 1;
      cursor: pointer;

      &.current {
        border: 1px solid #2aa7ff;

        .comp-menu {
          display: block;
        }
      }

      .comp-content {
        position: relative;
      }
    }
  }

  .comp-menu {
    position: absolute;
    display: none;
    top: 0;
    right: -38px;
    width: 32px;

    > a {
      display: inline-block;
      position: relative;
      width: 36px;
      height: 36px;
      margin-bottom: 2px;
      text-align: center;
      color: #666;
      background: #fff;
      font-size: 18px;
      line-height: 36px;

      &.disabled {
        color: #e4e4e4;
      }

      &:hover {
        &.disabled {
          color: #e4e4e4;
        }
        color: #333;
        .tips {
          display: block;
        }
      }

      .tips {
        font-size: 12px;
        position: absolute;
        left: 120%;
        background-color: #111;
        height: 18px;
        padding: 0 5px;
        line-height: 19px;
        border-radius: 2px;
        white-space: nowrap;
        display: none;
        color: #eee;
        top: 5px;

        &::before {
          content: ' ';
          display: block;
          position: absolute;
          left: -5px;
          top: 4.5px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 6px 5px 0;
          border-color: transparent #111 transparent transparent;
        }
      }
    }
  }

  .absolute-tpl {
    width: 377px;
    margin: 0 auto;
    transform: translateX(-20px);
    border-top: 1px solid #e8e8e8;

    &.current {
      border: 1px solid #2aa7ff;

      .comp-menu {
        display: block;
      }
    }
  }
  // quill 样式
  .ql-editor {
    padding: 0;
    em {
      font-style: italic;
    }
  }
}
</style>
