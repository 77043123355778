<!--
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-16 09:29:23
 * @LastEditTime: 2020-07-01 16:45:40
-->
<template>
  <div>
    <template>
      <div>
        <!--<el-form style="height: 40px; margin-bottom: 0px; width: 100%">
          <el-form-item class="small" style="margin: 0 16px" label="配置方式:">
            <template v-for="opt in [1, 2]">
              <el-radio v-model="selectindex" style="margin-left: 16px" :label="opt" :key="opt">{{
                opt === 1 ? '批量导入' : '手动添加'
              }}</el-radio>
            </template>
          </el-form-item>
        </el-form>-->
        <a-tabs @change="tabChangeCallback" type="card">
          <a-tab-pane tab="批量导入" key="1"></a-tab-pane>
          <a-tab-pane tab="手动添加" key="2"></a-tab-pane>
        </a-tabs>
      </div>
      <template v-if="selectindex === 1">
        <div class="selecttab">
          <div class="selectbtn" @click="selectgoodlistClick">选择商品</div>
          <div
            style="margin-top: 10px; width: 300px; height: 40px; line-height: 40px; color: #2aa7ff"
          >
            已选中
            {{
              items.length === 1 ? (items[0].goodsId === '' ? 0 : items.length) : items.length || 0
            }}
            商品
          </div>
        </div>
      </template>
      <template v-else-if="items && items.length" style="margin-top: 0px">
        <div
          class="form-list-panel"
          style="margin: 10px 16px 0px 16px"
          v-for="(item, idx) in items"
          :key="idx"
        >
          <a-form-model layout="vertical" :model="item" ref="goodsForm" :rules="rules">
            <div class="goods-config-box">
              <!-- ref="goodsId" -->
              <a-form-model-item
                prop="goodsId"
                label="商品ID"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 18 }"
              >
                <a-input
                  :maxLength="30"
                  type="text"
                  v-model.trim="item.goodsId"
                  @blur="getGoodsInfoById(idx)"
                ></a-input>
              </a-form-model-item>
              <!-- ref="goodsName" -->
              <a-form-model-item
                prop="goodsName"
                label="商品名称"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 18 }"
              >
                <a-input :maxLength="30" v-model.trim="item.goodsName"></a-input>
              </a-form-model-item>
              <!-- ref="tag1" -->
              <a-form-model-item
                label="标签1"
                prop="tag1"
                v-if="base[0].val === 1 || base[0].val === 2"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 18 }"
              >
                <a-input v-model.trim="item.tag1" :maxLength="6"></a-input>
              </a-form-model-item>
              <!-- ref="tag2" -->
              <a-form-model-item
                label="标签2"
                prop="tag2"
                v-if="base[0].val === 1"
                :label-col="{ span: 6 }"
                :wrapper-col="{ span: 18 }"
              >
                <a-input v-model.trim="item.tag2" :maxLength="6"></a-input>
              </a-form-model-item>
            </div>

            <div class="list-item-opt">
              <a href="javascript:;" v-if="idx !== 0" @click="upInput(idx)">
                <i class="el-icon-arrow-up"></i>
              </a>
              <a href="javascript:;" v-if="idx !== items.length - 1" @click="downInput(idx)">
                <i class="el-icon-arrow-down"></i>
              </a>
              <a href="javascript:;" v-if="items.length > 1" @click="delInput(idx)">
                <i class="el-icon-delete"></i>
              </a>
            </div>
          </a-form-model>
        </div>
      </template>
      <el-button
        v-if="selectindex === 2"
        icon="el-icon-plus"
        style="margin: 15px 16px 0px 16px"
        round
        @click="addInput"
        >添加</el-button
      >
    </template>
    <selectGoodList
      :itemList="items"
      v-if="selectgoodvisable"
      @submitClick="submitClick"
      @callback="importFileCallback"
    />
  </div>
</template>

<script>
import axios from '@/service/axios';
import { baseURL } from '@/config/index';
import util from '@/utils/h5-marker-util.js';
import compConfig from '@/config/h5.comp.config.js';
import { getGoodsBrief } from '@/service/goods';
import { checkNumber, checkNotspecial } from '@/utils/validator.js';
import { goodsImportAutoFetchTemplateUrl } from '@/const/template';
import selectGoodList from '@/components/h5maker/kuran/select-good-list';

export default {
  data() {
    return {
      handNewAdd: false,
      selectcount: 0,
      selectgoodvisable: false,
      fileList: [],
      goodsImportAutoFetchTemplateUrl: `${goodsImportAutoFetchTemplateUrl}?t=${new Date().getTime()}`,
      selectindex: 1,
      items: this.goods,
      defaultConf: util.copyObj(compConfig['kuran-goods-fullwidth']),
      rules: {
        goodsId: [
          { required: true, message: '请输入商品ID', trigger: 'blur' },
          { validator: checkNumber },
          { max: 30, message: '最多30个字', trigger: 'blur' },
        ],
        goodsName: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
          { max: 50, message: '最多50个字', trigger: 'blur' },
        ],
        tag1: [ {
            validator: checkNotspecial,
          }],
        tag2: [ {
            validator: checkNotspecial,
          }],
      },
    };
  },
  props: {
    goods: {
      type: Array,
      default: () => [],
    },
    base: {
      type: Array,
      default: () => [],
    },
    pageConfig: {
      type: Object,
    },
  },
  components: {
    selectGoodList,
  },
  watch: {
    goods: {
      handler: function (val) {
        this.items = val;
      },
      deep: true,
    },
    base: {
      handler: function (val) {
        this.baes = val;
      },
      deep: true,
    },
    pageConfig: {
      handler: function (val) {
        this.pageConfig = val;
      },
      deep: true,
    },
  },
  created() {
    console.log(this.pageConfig);
    this.$store.commit('setGettingGoodsStatus', false);//全局设置
  },
  methods: {
    // 上传完成回调
    importFileCallback() {
      this.selectgoodvisable = false;
    },
    //保存最新的数据
    submitClick(arr = []) {
      //先删除已有的，再添加新选的
      this.items.splice(0, this.items.length);
      var that = this;
      arr.filter(item => {
        // if (that.justAddItem(item)) {
        var dataitem=util.copyObj(this.defaultConf.action.config[0]);

          // var uindex = that.items.length-1;
         dataitem.goodsId = item.item_id;
         dataitem.tag1 = item.tag1 || '';
        dataitem.tag2 = item.tag2 || '';
        dataitem.tempGoodsInfo = item;
        dataitem.goodsName = item.item_name;
        dataitem.tempGoodsInfo.item_id__pict_url = item.item_id__pict_url||item.item_id__slide_images[0];
        dataitem.tempGoodsInfo.show_price = item.kuran_price;
        dataitem.tempGoodsInfo.show_commission_rate = item.external_commission_rate;
        dataitem.tempGoodsInfo.show_commission = ((dataitem.tempGoodsInfo.show_price * dataitem.tempGoodsInfo.show_commission_rate)/10000).toFixed(2);
        dataitem.tempGoodsInfo.item_id__volume = item.item_id__volume;
        dataitem.tempGoodsInfo.live_type=0;
        dataitem.tempGoodsInfo.item_id_id=item.item_id;
        dataitem.tempGoodsInfo.item_id__id=item.id;
        dataitem.tempGoodsInfo.item_id__item_id=item.item_id;
        dataitem.tempGoodsInfo.is_support_send=item.is_support_send===1;
        dataitem.tempGoodsInfo.goods_channel=2;
        dataitem.tempGoodsInfo.item_id__title = item.item_name;
          that.pageConfig.base.forEach(item => {
            if(item.attr==="page-tag" && item.val){
              dataitem.tempGoodsInfo.special_tag=item.val;
              dataitem.special_tag=item.val;
            }
          });
          console.log(dataitem);
        that.items.push(dataitem);
        // that.getGoodsInfoById(uindex);
          // that.getGoodsInfoById(uindex, true);
          /* const itemData = JSON.parse(JSON.stringify(that.items[uindex]));
        itemData.tempGoodsInfo = item;
        itemData.goodsName = item.item_id__title;
        that.items.splice(uindex, 1, itemData);*/
        // }
      });
      this.importFileCallback();
    },
    justAddItem(item) {
      var isadd = true;
      this.items.forEach(function (val) {
        if (val.goodsId === item.item_id) {
          isadd = false;
        }
      });
      return isadd;
    },
    //选中商品弹窗
    selectgoodlistClick() {
      // if(this.items.length<100){
        this.selectgoodvisable = true;
      // }
    },
    // tab切换回调
    tabChangeCallback(e) {
      this.selectindex = Number(e);
      if(this.selectindex===2){
        this.handNewAdd=true;
        this.$emit('submitHandAdd',true);
      }
    },
    // 选择文件上传修改
    beforeUpload(file) {
      this.fileList = [file];
      this.uploadFileHandler(file);
      return false;
    },
    onRemove(file, filelist) {
      this.setState(() => ({
        fileList: [],
      }));
      console.log(file, filelist);
    },
    // 手动上传
    uploadFileHandler(file) {
      var formData = new FormData();
      formData.append('file', file);
      formData.append('upload_type', 1);
      axios({
        method: 'post',
        url: `${baseURL}/api/v1/upload-file/`,
        data: formData,
      })
        .then(response => {
          const { err, res } = response;
          if (!err) {
            if (res.success) {
              this.$message.success('批量导入完成');
            } else {
              // 认证信息不对等错误
              // this.$message.error(`${res.message}`);
            }
          }
        })
        .catch(error => {
          this.$message.error(`${error}`);
        });
    },
    // 获取商品信息
    async getGoodsInfoById(idx) {
      const item_id = this.items[idx].goodsId;
      var isadd = false;
      this.items.forEach(function (val,index) {
        if (val.goodsId === item_id && index!=idx) {
          isadd = true;
        }
      });
      if(isadd){
        this.$message.error('添加的商品不能重复');
        return ;
      }
      if (item_id.length == 0) return;
      if (!/^[0-9]*$/.test(item_id)) return;
      const params = { item_ids: item_id };
      this.$store.commit('setGettingGoodsStatus', true);
      const { err, res } = await getGoodsBrief(params);
      this.$store.commit('setGettingGoodsStatus', false);
      if (!err) {
        if (res.success) {
          const itemData = JSON.parse(JSON.stringify(this.items[idx]));
          itemData.tempGoodsInfo = res.data && res.data[item_id];
          itemData.goodsName = res.data && res.data[item_id] && res.data[item_id].item_id__title;
          itemData.tag1=itemData.tag1 || res.data && res.data[item_id] && res.data[item_id].tag1;
          itemData.tag2=itemData.tag2 || res.data && res.data[item_id] && res.data[item_id].tag2;
          this.items.splice(idx, 1, itemData);
        } else {
          // this.items[idx].goodsId = '';
          this.validate();
        }
      /*  if (idx === this.items.length - 1 && this.items.length > 1 && issearch) {
          var firstitem = this.items[0];
          if (firstitem.goodsId === '' || firstitem.goodsId === null) {
            this.items.splice(0, 1);
          }
        }*/
      //获取最后一条，批量导入的时候不验证输入框的内容
        if(idx === this.items.length - 1 && this.selectindex===1){
          this.handNewAdd=false;
          this.$emit('submitHandAdd',false);
        }
      }
    },
    upInput(idx) {
      const tmp = util.copyObj(this.items[idx]);
      this.items.splice(idx, 1);
      this.items.splice(idx - 1, 0, tmp);
    },
    downInput(idx) {
      const tmp = util.copyObj(this.items[idx]);
      this.items.splice(idx, 1);
      this.items.splice(idx + 1, 0, tmp);
    },
    delInput(idx) {
      this.items.splice(idx, 1);
    },
    addInput() {
      if (this.items.length < 50) {
        this.items.push(util.copyObj(this.defaultConf.action.config[0]));
      } else {
        this.$alert('最多添加50个段落项！');
      }
    },
    async validate() {
      let isOk = [];
      for (let i = 0; i < this.$refs.goodsForm.length; i++) {
        let res = await this.$refs.goodsForm[i].validate();
        isOk.push(res);
      }
      return isOk.every(item => item);
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: #2aa7ff;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #2aa7ff;
}
.selecttab {
  margin: 0px 0px;
  height: 250px;
  padding: 0px 16px;
  position: relative;
  margin-top: -16px;
  border-bottom: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-left: 1px solid #e3e3e3;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.selectbtn {
  width: 100px;
  height: 30px;
  line-height: 30px;
  color: #333333;
  border-radius: 20px;
  border: 1px solid #e3e3e3;
}
</style>
