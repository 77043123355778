<template>
  <div>
    <template v-if="items && items.length">
      <div class="form-list-panel" v-for="(item, idx) in items" :key="idx">
        <a-form-model layout="vertical" :model="item" ref="paragraphsForm" :rules="rules">
          <a-form-model-item prop="content">
            <quill-editor ref="quillEditor" v-model="item.content" :options="editorOption" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </template>
  </div>
</template>

<script>
import util from "@/utils/h5-marker-util.js";
import compConfig from "@/config/h5.comp.config.js";
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { quillEditor } from 'vue-quill-editor';

export default {
  components: {
    quillEditor
  },
  data() {
    return {
      items: this.paragraphs,
      defaultConf: util.copyObj(compConfig["kuran-paragraph"]),
      editorOption: {
        placeholder: '请输入内容',
        modules: {
          toolbar: [
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'color': [] }],
            ['bold', 'italic', 'underline'], // , 'strike'
          ],
        }
      },
      rules: {
        content: [
          { required: true, message: "请输入段落文案", trigger: "blur" },
          { validator: this.validatorContentLength, trigger: "blur" }
        ],
      },
    };
  },
  props: {
    paragraphs: {
      type: Array
    }
  },
  watch: {
    paragraphs: {
      handler: function (val) {
        this.items = val
      },
      deep: true
    }
  },
  mounted () {
    // debugger
    // this.$refs.quillEditor[0].quill.setContents(this.paragraphs[0].content)
  },
  methods: {
    upInput(idx) {
      const tmp = util.copyObj(this.items[idx]);
      this.items.splice(idx, 1);
      this.items.splice(idx - 1, 0, tmp);
    },
    downInput(idx) {
      const tmp = util.copyObj(this.items[idx]);
      this.items.splice(idx, 1);
      this.items.splice(idx + 1, 0, tmp);
    },
    delInput(idx) {
      this.items.splice(idx, 1);
    },
    addInput() {
      if (this.items.length <= 20) {
        this.items.push(util.copyObj(this.defaultConf.action.config[0]));
      } else {
        this.$alert("最多添加20个段落项！");
      }
    },
    async validate () {
      const res = await this.$refs.paragraphsForm[0].validate();
      return res
    },
    
    // 自定义校验文字长度
    validatorContentLength(rule, value, callback) {
      const text = this.$refs.quillEditor[0].quill.getText();
      if (text.length > 501) {
        callback(new Error("最多500个字"));
      } else {
        callback();
      }
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
  .ql-editor em {
    font-style: italic;
  }
</style>
