<template>
  <div>
    <template v-if="inputs && inputs.length">
      <div class="form-list-panel" v-for="(input, idx) in inputs" :key="idx">
        <a-form-model layout="vertical" :model="input" ref="inputForm" :rules="rules">
          <a-form-model-item prop="desc" class="small" label="字段描述：" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-input v-model.trim="input.desc" placeholder="label名称"></a-input>
          </a-form-model-item>
          <a-form-model-item v-show="hideCodeName" prop="name" class="small" label="字段名称：" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-input v-model.trim="input.name" placeholder="接口对应的参数名"></a-input>
          </a-form-model-item>
          <a-form-model-item v-show="hideCodeName" prop="type" class="small" label="表单类型：" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-select defaultValue="text" v-model="input.type" placeholder="默认选项">
              <a-select-option v-for="opt in inputTypes" :key="opt.val" :value="opt.val">{{opt.name}}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="isNecessary" class="small" label="是否必填：" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <el-radio v-model="input.isNecessary" :label="1">是</el-radio>
            <el-radio v-model="input.isNecessary" :label="0">否</el-radio>
          </a-form-model-item>
          <a-form-model-item prop="placeholder" class="small" label="为空提示：" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <el-input v-model.trim="input.placeholder" placeholder="为空提示文案"></el-input>
          </a-form-model-item>
        </a-form-model>
        <div class="list-item-opt">
          <a href="javascript:;" v-if="idx !== 0"
             @click="upInput(idx)"><i class="el-icon-arrow-up"></i></a>
          <a href="javascript:;" v-if="idx !== inputs.length - 1"
             @click="downInput(idx)"><i class="el-icon-arrow-down"></i></a>
          <a href="javascript:;" v-if="idx > 0"
             @click="delInput(idx)"><i class="el-icon-delete"></i></a>
        </div>
      </div>
    </template>
    <el-button icon="el-icon-plus" style="margin-top:15px;" round @click="addInput">添加表单项</el-button>
  </div>
</template>

<script>
  import util from '@/utils/h5-marker-util.js'
  import compConfig from '@/config/h5.comp.config.js'
  export default {
    data() {
      return {
        hideCodeName: false,
        inputs: this.forms,
        defaultConf: util.copyObj(compConfig['kuran-form']),
        inputTypes: [
          {
            name: '文本',
            val: 'text'
          }, {
            name: '数字',
            val: 'number'
          },
          //  {
          //   name: '日期选择',
          //   val: 'date'
          // }, {
          //   name: '下拉选择',
          //   val: 'select'
          // }, {
          //   name: 'radio单选',
          //   val: 'radio'
          // }, {
          //   name: 'checkbox多选',
          //   val: 'checkbox'
          // }
        ],
        rules: {
          desc: [
            { required: true, message: "请输入字段描述", trigger: "blur" },
            { max: 6, message: "最多6个字", trigger: "blur" }
          ]
        }
      }
    },
    props: {
      forms: {
        type: Array
      }
    },
    watch: {
      forms: {
        handler: function (val) {
          this.inputs = val
        },
        deep: true
      }
    },
    methods: {
      upInput(idx) {
        const tmp = util.copyObj(this.inputs[idx])
        this.inputs.splice(idx, 1)
        this.inputs.splice(idx - 1, 0, tmp)
      },
      downInput(idx) {
        const tmp = util.copyObj(this.inputs[idx])
        this.inputs.splice(idx, 1)
        this.inputs.splice(idx + 1, 0, tmp)
      },
      delInput(idx) {
        this.inputs.splice(idx, 1)
      },
      addInput() {
        
        if (this.inputs.length < 10) {
          const formItemDefault = {
            errorMsg: {
              desc: '请填写字段描述'
            },
            desc: '',
            type: 'text',
            name: 'name_' + Date.now(),
            placeholder: '',
            isNecessary: 1
          }
          
          this.inputs.push(formItemDefault)
        } else {
          this.$alert('最多添加10个表单项！')
        }
      },
      async validate () {
        let isOk = []
        for (let i = 0; i < this.$refs.inputForm.length; i++) {
          let res = await this.$refs.inputForm[i].validate()
          isOk.push(res)
        }
        return isOk.every(item => item)
      }
    }
  }
</script>

<style rel="stylesheet/scss" lang="scss">

</style>
