<template>
  <div>
    <template v-if="items && items.length">
      <div class="form-list-panel" v-for="(item, idx) in items" :key="idx">
        <a-form-model layout="horizontal" :model="item" ref="titleForm" :rules="rules">
          <a-form-model-item prop="content" class="small" label="标题文案：" :label-col="{span: 5}" :wrapperCol="{span: 19}">
            <a-input v-model.trim="item.content" :maxLength="8" placeholder="标题文案"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: Array
    }
  },
  data() {
    return {
      items: this.title,
      rules: {
        content: [
          { required: true, message: "请输入标题文案", trigger: "blur" },
          { max: 10, message: "最多10个字", trigger: "blur" }
        ]
      }
    };
  },
  watch: {
    title: {
      handler(val) {
        this.items = val;
      },
      deep: true
    }
  },
  methods: {
    async validate() {
      const res = await this.$refs.titleForm[0].validate();
      return res;
    }
  }
};
</script>

<style>
</style>
