<!--
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 白青
 * @Date: 2020-05-05 10:06:21
 * @LastEditTime: 2020-07-18 15:11:43
 -->
<template>
  <div>
    <template v-if="items && items.length">
      <div class="form-list-panel" v-for="(item, idx) in items" :key="idx">
        <a-form-model layout="horizontal" :model="item" ref="floorMenuForm" :rules="rules">
          <a-form-model-item
            prop="text"
            class="small"
            label="导航文案："
            :label-col="{span: 5}"
            :wrapperCol="{span: 19}"
          >
            <a-input v-model="item.text" placeholder="跳转项文案" />
          </a-form-model-item>
          <a-form-model-item
            prop="click"
            class="small"
            label="跳转模块："
            :label-col="{span: 5}"
            :wrapperCol="{span: 19}"
          >
            <a-input
              :value="item.click ? item.click.domName || item.click.href : ''"
              placeholder="请配置跳转"
              disabled
            />
          </a-form-model-item>
          <a-form-model-item
            prop="click"
            class="small"
            label="跳转到："
            :label-col="{span: 5}"
            :wrapperCol="{span: 19}"
            style="display:none;"
          >
            <a-input :value="item.click ? item.click.href : ''" placeholder="请配置跳转" disabled />
          </a-form-model-item>
          <a-form-model-item
            class="small"
            label="点击配置："
            :label-col="{span: 5}"
            :wrapperCol="{span: 19}"
          >
            <el-button icon="el-icon-edit" round @click="showClick(item, idx)">配置跳转</el-button>
          </a-form-model-item>
        </a-form-model>
        <div class="list-item-opt">
          <a href="javascript:;" v-if="idx !== 0" @click="upItem(idx)">
            <i class="el-icon-arrow-up"></i>
          </a>
          <a href="javascript:;" v-if="idx !== items.length - 1" @click="downItem(idx)">
            <i class="el-icon-arrow-down"></i>
          </a>
          <a href="javascript:;" v-if="idx > 0" @click="delItem(idx)">
            <i class="el-icon-delete"></i>
          </a>
        </div>
      </div>
    </template>
    <el-button icon="el-icon-plus" style="margin-top:15px;" round @click="addItem">添加跳转项</el-button>
  </div>
</template>

<script>
import util from "@/utils/h5-marker-util.js";
import compConfig from "@/config/h5.comp.config.js";
export default {
  data() {
    return {
      defaultConf: util.copyObj(compConfig["kuran-floor-menu"]),
      items: this.menus,
      rules: {
        text: [
          { required: true, message: "请输入导航项文案", trigger: "blur" },
          { max: 6, message: "最多6个字", trigger: "blur" }
        ],
        click: [{ required: true, message: "请配置跳转", trigger: "blur" }]
      }
    };
  },
  props: {
    menus: {
      type: Array
    }
  },
  watch: {
    menus: {
      async handler(val) {
        this.items = val;
        // 校验导航跳转模块
        for (let i = 0; i < this.$refs.floorMenuForm.length; i++) {
          try {
            await this.$refs.floorMenuForm[i].validate()
          } catch (error) {
            console.log('kuranFloorMenuItem watch menus', error);
          }
        }
      },
      deep: true
    }
  },
  methods: {
    showClick(banner, idx) {
      this.$bus.$emit("click:show", idx, ["page"]);
    },
    upItem(idx) {
      const tmp = util.copyObj(this.items[idx]);
      this.items.splice(idx, 1);
      this.items.splice(idx - 1, 0, tmp);
    },
    downItem(idx) {
      const tmp = util.copyObj(this.items[idx]);
      this.items.splice(idx, 1);
      this.items.splice(idx + 1, 0, tmp);
    },
    delItem(idx) {
      this.items.splice(idx, 1);
    },
    addItem() {
      if (this.items.length < 18) {
        this.items.push(util.copyObj(this.defaultConf.action.config[0]));
      } else {
        this.$alert("最多添加18个导航项！");
      }
    },
    async validate() {
      let isOk = [];
      for (let i = 0; i < this.$refs.floorMenuForm.length; i++) {
        try {
          let res = await this.$refs.floorMenuForm[i].validate();
          isOk.push(res);
        } catch (error) {
          isOk.push(false);
          console.log('kuranFloorMenuItem validate', error);
        }
      }
      return isOk.every(item => item);
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
</style>
