<!--
 * @Description: 批量新增弹层
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-12 17:03:34
 * @LastEditTime: 2020-09-28 15:24:31
 -->
<template>
  <div style="margin: 0px">
    <a-modal
      title="批量选择商品"
      class="batch-upload-goods-dialog"
      style="width: 1200px"
      v-model="batchUploadGoodsDialogVisible"
      centered
      :bodyStyle="{ padding: 0 }"
      :footer="null"
      @cancel="hideImportFile"
    >
      <div style="margin-top: 15px">
        <GoodsSearchBar
          v-auth="'goods_manage_goods_list'"
          @callback="searchBarCallback"
          @handleUploadAdd="handleUploadAdd"
          @handleUploadDelete="handleUploadDelete"
          :searchParams="searchParams"
          :tableindex="selectTab"
          :selectedRowKeys="selectedRowKeys"
          :selectedNewRowKeys="selectedNewRowKeys"
          :goodsCate="goodsCatesList"
        />
      </div>
      <div style="width: 100%; height: 680px">
        <a-tabs style="margin-bottom: 0px;height: 38px !important;" @change="tabChangeCallback" type="card">
          <a-tab-pane tab="待添加" key="1"></a-tab-pane>
          <a-tab-pane tab="已添加" key="2"></a-tab-pane>
        </a-tabs>
        <div v-if="selectTab===1" style="width: 100%; padding: 0 0px 0 0px; float: left;">
          <a-table
            id="one"
            style="margin-top: 0px;height: 630px;border: 1px solid #e6e6e6;"
            :scroll="{ x: 300, y:520 }"
            :rowKey="record => record.item_id"
            :columns="goodsColumns"
            :dataSource="list"
            :pagination="pagination"
            :loading="listLoading"
            :locale="{ emptyText: '' }"
            @change="handleTableChange"
            :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          >
            <template slot="goods_img" slot-scope="record">
              <my-image v-if="record.item_id__pict_url||record.item_id__slide_images[0]" :imgUrl="record.item_id__pict_url||record.item_id__slide_images[0]"></my-image>
            </template>
            <template slot="goods_id" slot-scope="record">{{ record.item_id }}</template>
            <template slot="name" slot-scope="record">
              <span v-html="setName(record.item_name)"></span>
            </template>
            <template slot="is_support_send" slot-scope="record">
              <a-tag :color="sampleModeMethods(record.is_support_send).color">{{
                sampleModeMethods(record.is_support_send).label
              }}</a-tag>
            </template>
            <template slot="discount_price" slot-scope="record">
              ￥{{ record.kuran_price }}
            </template>
            <template slot="commission_rate" slot-scope="record">
              {{ record.external_commission_rate / 100 }}%
            </template>
            <template slot="commission_money" slot-scope="record">
              {{ ((record.external_commission_rate * record.kuran_price) / 10000).toFixed(2) }}
            </template>
            <template slot="commission_service_rate" slot-scope="record">
              {{record.campaign_product_service_rate ? record.campaign_product_service_rate+'%' : ''}}
            </template>
            <template slot="kr_status" slot-scope="record">
              <a-tag v-if="record.is_on_sale === 1" color="green">上架</a-tag>
              <a-tag v-if="record.is_on_sale === 2" color="red">下架</a-tag>
            </template>
            <template slot="is_recycle" slot-scope="record">
              <a-tag v-if="record.is_recycle === 0" color="green">否</a-tag>
              <a-tag v-if="record.is_recycle === 1" color="red">是</a-tag>
            </template>
            <template slot="operation" slot-scope="record">
              <a-button
                v-if="justAddItem(record)"
                type="primary"
                size="small"
                class="mr-10"
                @click="handleAddGoods(record)"
                v-auth="'goods_manage_modify_goods'"
                >添加</a-button
              >
              <a-button
                v-else
                type="primary"
                size="small"
                class="mr-10"
                style="background-color: #e3e3e3; border: none"
                v-auth="'goods_manage_modify_goods'"
                >添加</a-button
              >
            </template>
            <template slot="goodtag" slot-scope="record">
               <!-- <a-input
                  :maxLength="6"
                  @blur="commitgood(record)"
                  v-model="record.tag1"
                  style="display: inline-block;width: 120px;float: left" placeholder="标签1" />
                <a-input :maxLength="6"  @blur="commitgood(record)"
                   v-model="record.tag2" style="display: inline-block;width: 120px;float: left;margin-left: 10px"
                   placeholder="标签2" />
              -->
              <div style="height: 70px !important;width: 250px;overflow:hidden;margin-top: -15px;">
                <div  style="width: 250px;height: 50px !important;padding-top: 25px !important;">
                  <a-form-model layout="vertical" style="width: 250px;height: 28px;margin: 0px;padding: 0px;" :model="record" :rules="rules">
                    <a-form-model-item
                      prop="tag1"
                      style="width: 120px;display: inline-block;float: left;height: 28px;"
                    >
                      <a-input style="height: 28px !important;font-size: 13px;width: 120px;" placeholder="标签1"  @blur="commitgood(record,1)" v-model.trim="record.tag1" :maxLength="6"></a-input>
                    </a-form-model-item>
                    <a-form-model-item
                      prop="tag2"
                      style="width: 120px;display: inline-block;float: left;margin-left: 10px;height: 28px;"
                    >
                      <a-input style="height: 28px !important;font-size: 13px;width: 120px;"   placeholder="标签2" @blur="commitgood(record,1)" v-model.trim="record.tag2" :maxLength="6"></a-input>
                    </a-form-model-item>
                  </a-form-model>
                </div>
              </div>
            </template>
          </a-table>
        </div>
        <div v-if="selectTab===2"  style="width: 100%; padding: 0 0px 0 0px; float: left">
          <a-table
            id="two"
            style="margin-top: 0px;height: 630px;border: 1px solid #e6e6e6;"
            :scroll="{ x: 300, y:520 }"
            :rowKey="record => record.item_id"
            :columns="goodsColumns"
            :dataSource="selectlist"
            :pagination="selectpagination"
            :loading="listLoading"
            @change="handleSelectTableChange"
            :rowSelection="{ selectedRowKeys: selectedNewRowKeys, onChange: onNewSelectChange }"
          >
            <template slot="goods_img" slot-scope="record">
              <my-image v-if="record.item_id__pict_url||record.item_id__slide_images[0]" :imgUrl="record.item_id__pict_url||record.item_id__slide_images[0]"></my-image>
            </template>
            <template slot="goods_id" slot-scope="record">{{ record.item_id }}</template>
            <template slot="name" slot-scope="record">
              <span v-html="setName(record.item_name)"></span>
            </template>
            <template slot="is_support_send" slot-scope="record">
              <a-tag :color="sampleModeMethods(record.is_support_send).color">{{
                sampleModeMethods(record.is_support_send).label
              }}</a-tag>
            </template>
            <template slot="discount_price" slot-scope="record">
              ￥{{ record.kuran_price }}
            </template>
            <template slot="commission_rate" slot-scope="record">
              {{ record.external_commission_rate / 100 }}%
            </template>
            <template slot="commission_money" slot-scope="record">
              {{ ((record.external_commission_rate * record.kuran_price) / 10000).toFixed(2) }}
            </template>
            <template slot="commission_service_rate" slot-scope="record">
              {{record.campaign_product_service_rate ? record.campaign_product_service_rate+'%' : ''}}
            </template>
            <template slot="kr_status" slot-scope="record">
              <a-tag v-if="record.is_on_sale === 1" color="green">上架</a-tag>
              <a-tag v-if="record.is_on_sale === 2" color="red">下架</a-tag>
            </template>
            <template slot="campaign_product_status" slot-scope="record">
              <a-tag v-if="record.campaign_product_status === 0" color="green">否</a-tag>
              <a-tag v-if="record.campaign_product_status === 1" color="red">是</a-tag>
            </template>
            <template slot="operation" slot-scope="record">
              <a-button
                type="primary"
                size="small"
                class="mr-10"
                @click="handleDeleteGoods(record)"
                v-auth="'goods_manage_modify_goods'"
                >删除</a-button
              >
            </template>
            <template slot="goodtag" slot-scope="record">
              <div style="height: 70px !important;width: 250px;overflow:hidden;margin-top: -15px;">
                <div  style="width: 250px;height: 50px !important;padding-top: 25px !important;">
                  <a-form-model layout="vertical" style="width: 250px;height: 28px;margin: 0px;padding: 0px;" :model="record" :rules="rules">
                    <a-form-model-item
                      prop="tag1"
                      style="width: 120px;display: inline-block;float: left;height: 28px;"
                    >
                      <a-input style="height: 28px !important;font-size: 13px;width: 120px;" placeholder="标签1"  @blur="commitgood(record,2)" v-model.trim="record.tag1" :maxLength="6"></a-input>
                    </a-form-model-item>
                    <a-form-model-item
                      prop="tag2"
                      style="width: 120px;display: inline-block;float: left;margin-left: 10px;height: 28px;"
                    >
                      <a-input style="height: 28px !important;font-size: 13px;width: 120px;"   placeholder="标签2" @blur="commitgood(record,2)" v-model.trim="record.tag2" :maxLength="6"></a-input>
                    </a-form-model-item>
                  </a-form-model>
                </div>
              </div>
            </template>
          </a-table>
        </div>
      </div>

      <div class="ant-modal-footer" style="padding-top: 0px;border: none;position: absolute;bottom: 20px;right: 20px;height: 40px;">
        <div>
          <button type="button" class="ant-btn ant-btn-normal ant-own-btn" @click="hideImportFile">
            <span>取消</span>
          </button>
          <button type="button" class="ant-btn ant-btn-primary ant-own-btn" @click="handleUploadGoods">
            <span>保存</span>
          </button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import GoodsSearchBar from '@/components/h5maker/kuran/goods-selectsearch-bar';
import { getKrGoodsList,editGoodsTagsIn } from '@/service/goods';
import { sendSampleOptions } from '@/const/goods-cate.js';
import {
  checkNotspecial
} from "@/utils/validator";
// 商品列表
const goodsColumns = [
  {
    title: "商品图片",
    fixed: 'left',
    width: 100,
    scopedSlots: { customRender: "goods_img" },
  },
  {
    title: '商品编码',
    fixed: 'left',
    width: 140,
    scopedSlots: { customRender: 'goods_id' },
  },
  {
    title: '商品名称',
    scopedSlots: { customRender: 'name' },
    width: 200,
    align: 'left',
  },
  {
    title: '商品类目',
    dataIndex: 'item_id__first_cat_name',
    width: 150,
    align: 'left',
  },
  {
    title: '销售价',
    scopedSlots: { customRender: 'discount_price' },
    width: 150,
    align: 'left',
  },
  {
    title: '佣金率',
    scopedSlots: { customRender: 'commission_rate' },
    width: 100,
    align: 'left',
  },
  {
    title: '佣金',
    scopedSlots: { customRender: 'commission_money' },
    width: 150,
    align: 'left',
  },
  {
    title: '服务费率',
    scopedSlots: { customRender: 'commission_service_rate' },
    width: 100,
    align: 'left',
  },
  {
    title: '合作状态',
    dataIndex: 'item__is_sale',
    // scopedSlots: { customRender: 'campaign_product_status' },
    width: 100,
    align: 'left',
  },
  {
    title: '寄样模式',
    align: 'center',
    scopedSlots: { customRender: 'is_support_send' },
    width: 100,
  },
  {
    title: "标签",
    scopedSlots: { customRender: "goodtag" },
    width: 270,
    align: "left",
  },
  {
    title: '所属店铺',
    dataIndex: 'supplier',
    width: 200,
    align: 'left',
  },
  {
    title: '上架状态',
    scopedSlots: { customRender: 'kr_status' },
    width: 110,
  },
  {
    title: '操作',
    align: 'center',
    fixed: 'right',
    width: 80,
    scopedSlots: { customRender: 'operation' },
  },
];
export default {
  name: 'ImportFile',
  props: {
    itemList:{
        type: Array,
        default: () => [],
    },
  },
  data() {
    return {
      rules: {
        tag1: [ {
          validator: checkNotspecial,
        }],
        tag2: [ {
          validator: checkNotspecial,
        }],
      },
      isfirst:true,
      showimg:false,
      selectImg:'',
      // （0:待审核 1:审核通过 2:审核拒绝 3:建议二次报名 4:未合作取消，5:合作中取消）
      hezuolist: ['待审核', '合作中', '审核拒绝', '建议二次报名', '未合作取消', '合作中取消'],
      selectTab: 1,
      sendSampleOptions,
      // 选中的数据
      selectedRows: [],
      selectedRowKeys: [],
      // 选中的数据
      selectedNewRows: [],
      selectedNewRowKeys: [],
      list: [],
      selectlist: [],
      pagination: {
        size: 'small',
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: this.list?this.list.length:0,
        showTotal: total =>
          `共 ${total} 条记录       第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize,
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => (this.pagination.pageSize = pageSize),
      },
      selectpagination: {
        size: 'small',
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: this.selectlist?this.selectlist.length:0,
        showTotal: total =>
          `共 ${total} 条记录 /限50条      第 ${this.selectpagination.current}/${Math.ceil(
            total / this.selectpagination.pageSize,
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) => (this.selectpagination.pageSize = pageSize),
      },
      goodsColumns,
      batchUploadGoodsDialogVisible: true,
      // 商品类目数据
      goodsCatesList: [],
      listLoading: false,
      // 查询条件
      searchParams: {
        search: '',
        type: 2,
        is_on_sale: undefined,
        douyin_status: undefined,
        is_recycle: undefined,
        data_source: undefined,
        source: undefined,
        goods_channel: undefined,
        is_support_new: undefined,
        time_type: undefined,
        start_time: undefined,
        end_time: undefined,
        custom_cat: undefined,
        supplier_obj_id: undefined,
        brand_id: undefined,
        kuran_price: [],
        external_commission_rate: [],
        campaign_product_service_rate: [],
        item_tag: undefined,
      },
    };
  },
  components: {
    GoodsSearchBar,
  },
  created() {
    if( this.itemList && this.itemList.length>0){
      this.getonceselectList();
    }
  },
  methods: {
    //提交信息
    validator(value){
      if (value === '' || value === undefined) {
        return true;
      } else {
        ////[^\u4E00-\u9FA5]/g
        ///[^\a-\z\A-\Z0-9\u4E00-\u9FA5\@\.]/g
        if (!(/^[\u4E00-\u9FA5A-Za-z0-9.]+$/.test(value))) {
          return false;
        }else if(value.length===1||value.length>6) {
          return false;
        } else {
          return true;
        }
      }
    },
    async commitgood(record,type=1) {
      record.tag1 = record.tag1 || '';
      record.tag2 = record.tag2 || '';
      var istag1=this.validator(record.tag1);
      var istag2=this.validator(record.tag2);
      var that=this;
      if(istag1&&istag2){
        if(record.tag1===record.tag2 && record.tag1!=='') {
          this.$message.warning('标签1与标签2不能一样！！！');
          return false;
        }
        var tag1=record.item_tag_list?record.item_tag_list[0]:'';
        var tag2=record.item_tag_list?record.item_tag_list[1]:'';
        if(record.tag1 === tag1 && record.tag2 === tag2){
          return ;
        }
        const token = this.$store.getters.getToken;
        const  Authorization=token ? token : undefined;
        var data={
          item_id: record.item_id,
          Authorization: Authorization,
          item_tag_list: [record.tag1||'',record.tag2||'']
        }
        const { err, res } = await editGoodsTagsIn(data);
        if (!err) {
          if (res.success) {
            record.item_tag_list=[record.tag1||'',record.tag2||''];
            if(type===1) {
              //已添加的
              that.selectlist.forEach(function (val,index) {
                if (val.item_id === record.item_id) {
                  val.item_tag_list=[record.tag1||'',record.tag2||''];
                  val.tag1=record.tag1||'';
                  val.tag2=record.tag2||'';
                  that.selectlist[index]=val;
                }
              });
            }
            this.$message.success("更新成功！");
          }
        }
      }
    },
    async getonceselectList(){
      if (this.itemList.length===1){
        var goodsId=this.itemList[0].goodsId||'';
        if(goodsId===''){
          return ;
        }
      }
      if(this.itemList.length>0 && this.isfirst) {
        this.isfirst=false;
        var arr=[];
        this.itemList.filter(item=>{
          if(item.goodsId&&item.goodsId!=='') {
            arr.push(item.goodsId);
          }
        });
      const data = {
        page: 1,
        page_size: arr.length,
        goods_item_id_list: arr
      };
      this.listLoading = true;
      const { err, res } = await getKrGoodsList(data);
      this.listLoading = false;
      var that=this;
      if (!err) {
        if (res.success) {
          var newarr=[];
          that.itemList.filter(subitem => {
            res.data.results.filter(item=>{
              if(item.item_id===subitem.goodsId){
                item.tag1 = subitem.tag1||'';
                item.tag2 = subitem.tag2||'';
                if(item.tag1===""){
                  item.tag1=item.item_tag_list?item.item_tag_list[0]:'';
                }
                if(item.tag2===""){
                  item.tag2=item.item_tag_list?item.item_tag_list[1]:'';
                }
                if(item.campaign_product_status){
                  item.item__is_sale = this.hezuolist[Number(item.campaign_product_status)];
                }else{
                  item.item__is_sale='';
                }
                const _obj = Object.assign({}, item);
                newarr.push(_obj);
                return;
              }
            });
          });
          this.selectlist=newarr.concat(this.selectlist)
        }
      }
      }
    },
    showImgClick(record){
      this.selectImg=record.item_id__pict_url||record.item_id__slide_images[0];
      this.showimg=true;
    },
    // tab切换回调
    tabChangeCallback(e) {
      this.selectTab = Number(e);
      if(this.selectTab===1){
        this.searchBarCallback(this.searchParams,true);
      }
    },
    // 寄样模式
    sampleModeMethods(valCompare) {
      for (const val of this.sendSampleOptions) {
        if (val.value === valCompare) {
          return val;
        }
      }
    },
    handleUploadAdd() {
      var that = this;
      var isnotolist = false;
      this.selectedRows.forEach(function (val) {
        if (that.justAddItem(val)) {
          if (that.selectlist.length < 50) {
            const _obj = Object.assign({}, val);
            that.selectlist.push(_obj);
          } else {
            isnotolist = true;
          }
        }
      });
      if (isnotolist) {
        this.$message.warning('已添加商品数不能超过50个');
      }
      this.selectedRowKeys = [];
      this.selectedRows = [];
    },
    justAddItem(item) {
      var isadd = true;
      this.selectlist.forEach(function (val) {
        if (val.id === item.id) {
          isadd = false;
        }
      });
      this.itemList.forEach(function (val) {
        if (val.goodsId === item.item_id) {
          isadd = false;
        }
      });
      return isadd;
    },
    justDeleteItem(item) {
      var index = this.selectlist.indexOf(item);
      if (index > -1) {
        this.selectlist.splice(index, 1);
      }
    },
    handleUploadDelete() {
      var that = this;
      this.selectedNewRows.forEach(function (val) {
        that.justDeleteItem(val);
      });
      this.selectedNewRows = [];
      this.selectedNewRowKeys = [];
      // this.getGoodsList();
    },
    setName(e) {
      //文字超出显示省略号
      return (
        '<span  title="' +
        e +
        '" style="display:inline-block;width: 100%;text-align: left;' +
        '        overflow : hidden;' +
        '        text-overflow: ellipsis;' +
        ' display: -webkit-box; ' +
        '-webkit-box-orient: vertical; ' +
        '-webkit-box-pack: left; ' +
        '-webkit-box-align: left; ' +
        '-webkit-line-clamp:2; ' +
        'overflow: hidden;">' +
        e +
        '</span>'
      );
    },
    // 表格分页
    handleTableChange(pagination) {
      this.selectedRowKeys = [];
      this.selectedRows = [];
      if (pagination) this.pagination.current = pagination.current;
      this.getGoodsList();
    },
    handleSelectTableChange(pagination) {
      this.selectedNewRowKeys = [];
      this.selectedNewRows = [];
      if (pagination) this.selectpagination.current = pagination.current;
    },
    // 获取商品列表
    async getGoodsList() {
      const data = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        search: this.searchParams.search,
        is_recycle: this.searchParams.is_recycle,
        is_on_sale: this.searchParams.is_on_sale,
        data_source: this.searchParams.data_source,
        source: this.searchParams.source,
        campaign_product_status: this.searchParams.douyin_status,
        goods_channel: this.searchParams.goods_channel,
        is_support_new: this.searchParams.is_support_new,
        time_type: this.searchParams.time_type,
        start_time: this.searchParams.start_time,
        end_time: this.searchParams.end_time,
        custom_cat: this.searchParams.custom_cat,
        supplier_obj_id: this.searchParams.supplier_obj_id,
        kuran_price_start: this.searchParams.kuran_price[0],
        kuran_price_end: this.searchParams.kuran_price[1],
        external_commission_rate_start: this.searchParams.external_commission_rate[0],
        campaign_product_service_rate_start:this.searchParams.campaign_product_service_rate[0],
        external_commission_rate_end: this.searchParams.external_commission_rate[1],
        campaign_product_service_rate_end: this.searchParams.campaign_product_service_rate[1],
        item_tag: this.searchParams.item_tag,
      };
      if (data.external_commission_rate_start) {
        data.external_commission_rate_start = parseInt(data.external_commission_rate_start * 100.0);
      }
      if (data.external_commission_rate_end) {
        data.external_commission_rate_end =  parseInt(data.external_commission_rate_end * 100.0);
      }
      if (data.campaign_product_service_rate_start) {
        data.campaign_product_service_rate_start = parseInt(data.campaign_product_service_rate_start);
      }
      if (data.campaign_product_service_rate_end) {
        data.campaign_product_service_rate_end = parseInt(data.campaign_product_service_rate_end);
      }
      this.listLoading = true;
      const { err, res } = await getKrGoodsList(data);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results.map(item => {
            item.tag1=item.item_tag_list?item.item_tag_list[0]:'';
            item.tag2=item.item_tag_list?item.item_tag_list[1]:'';
            if(item.campaign_product_status){
              item.item__is_sale = this.hezuolist[Number(item.campaign_product_status)];
            }else{
              item.item__is_sale='';
            }
            return item;
          });
          pagination.total = res.data.count;
          this.pagination = pagination;
        }else{
          this.list=[];
        }
      }
    },
    handleAddGoods(item) {
      var isnotolist = false;
      if (this.justAddItem(item)) {
        if (this.selectlist.length < 50) {
          const _obj = Object.assign({}, item);
          this.selectlist.push(_obj);
        } else {
          isnotolist = true;
        }
      }
      if (isnotolist) {
        this.$message.warning('已添加商品数不能超过50个');
      }
    },
    handleDeleteGoods(item) {
      this.justDeleteItem(item);
    },
    // 选中不选中行
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    onNewSelectChange(selectedNewRowKeys, selectedRows) {
      this.selectedNewRowKeys = selectedNewRowKeys;
      this.selectedNewRows = selectedRows;
    },
    // 查询
    searchBarCallback(res,istab=false) {
      this.searchParams = Object.assign({}, res);
      if (
        this.searchParams.search.length > 0 ||
        this.searchParams.kuran_price.length > 0 ||
        this.searchParams.external_commission_rate.length > 0 ||
        this.searchParams.campaign_product_service_rate.length > 0 ||
        this.searchParams.supplier_obj_id ||
        this.searchParams.is_on_sale ||
        this.searchParams.douyin_status || this.searchParams.item_tag
      ) {
        if(istab===false){
          this.pagination.current=1;
        }
        this.getGoodsList();
      }
    },
    // 隐藏批量新增弹层
    hideImportFile() {
      this.$emit('callback');
    },
    // 手动上传
    handleUploadGoods() {
      if (this.selectlist.length > 50) {
        this.$message.warning('已添加商品数不能超过50个');
        return;
      }
      this.$emit('submitClick', this.selectlist);
    },
  },
};
</script>
<style lang="less" scoped>
.batch-upload-goods-dialog {
  width: 100% !important;
  .mb-10 {
    margin-bottom: 10px;
  }
  .upload-spin {
    text-align: center;
  }
  .lh-120 {
    line-height: 125%;

    p {
      margin-bottom: 20px !important;
    }
  }
  .ant-form-vertical .ant-form-explain {
    font-size: 12px;
  }
  /deep/ .ant-form-vertical .ant-form-explain {
    font-size: 12px;
  }
  align-items: flex-start;
  justify-content: flex-start;
  justify-self: flex-start;
  /deep/ .ant-modal {
    width: 92% !important;
    min-width: 1000px;
    max-width: 1700px;
    height: 90% !important;
    justify-self: flex-start;
    align-items: flex-start;
    .ant-modal-content {
      /*width: 1200px !important;*/
      padding: 0 20px 0 20px;
      text-align: left;
      .ant-modal-centered {
        /*width: 1200px;*/
      }
    }
    .ant-modal-wrap .ant-modal-centered {
      align-items: flex-start;
    }
  }
  /deep/ .ant-table-thead > tr > th {
    white-space: nowrap;
  }
  .ant-table-row td {
    white-space: nowrap;
  }
  /deep/ .ant-table-placeholder {
    margin-top: -400px;
    height: 400px !important;
    border: none;
  }
  /deep/ .ant-table-content{
    height: 580px !important;
  }
  /deep/ .ant-table-body {
    height: 520px !important;
    border-bottom: 1px solid #e6e6e6;
  }
  /deep/ .ant-pagination mini, .ant-table-pagination{
    margin: 0 20px;
  }
  /deep/ .ant-table-pagination.ant-pagination{
    float: left;
    padding-left: 10px;
    margin-top: 7px;
    height: 30px;
    line-height: 30px;
  }
  .ant-own-btn {
    height: 30px;
    line-height: 30px;
  }
}
.el-img{
  /deep/ .el-image__inner {
    width: 54px;
    height: 54px;
  }
  .el-image__inner {
    width: 54px;
    height: 54px;
  }
}

</style>
