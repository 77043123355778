<!--
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-05 16:44:54
 * @LastEditTime: 2020-09-28 15:23:57
 * @Description: 商品管理搜索条
 -->
<template>
  <div class="goods-search-bar">
    <a-form class="filter-condition-form" layout="inline" :form="form" @submit="handleFormSubmit">
      <a-form-item>
        <a-input
          style="width: 180px"
          v-model="searchParams.search"
          allowClear
          placeholder="请输入商品名称/编码"
        />
      </a-form-item>
      <a-form-item>
        <goods-range title="商品销售价" v-model="searchParams.kuran_price" />
      </a-form-item>
      <a-form-item>
        <goods-range
          title="佣金率"
          :isneedbaifenhao="true"
          v-model="searchParams.external_commission_rate"
        />
      </a-form-item>
      <a-form-item>
        <goods-range
          title="服务费率"
          :isneedbaifenhao="true"
          v-model="searchParams.campaign_product_service_rate"
        />
      </a-form-item>
      <a-form-item>
        <a-select
          style="width: 170px"
          placeholder="请输入店铺名称"
          v-model="searchParams.supplier_obj_id"
          :default-active-first-option="false"
          show-search
          :allowClear="true"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearchSupplier"
        >
          <a-select-option v-for="item in supplierList" :key="item.id">{{
            item.supplier_name
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select
          style="width: 120px"
          placeholder="上架状态"
          v-model="searchParams.is_on_sale"
          allowClear
        >
          <a-select-option v-for="item in inventoryStatusOptions" :key="item.value">{{
            item.label
          }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item style="width: 150px">
        <a-input
          v-model="searchParams.item_tag"
          allowClear
          :maxLength="6"
          placeholder="商品标签"
          v-decorator="[
            'searchParams.item_tag',
            {
              rules: [
                {
                  validator: checkNotspecial,
                },
              ],
            },
          ]"
        />
      </a-form-item>
      <a-form-item>
        <a-select
          style="width: 120px"
          placeholder="合作状态"
          v-model="searchParams.douyin_status"
          allowClear
        >
          <a-select-option value="1">合作中</a-select-option>
          <a-select-option value="5">合作中取消</a-select-option>
          <!--          <a-select-option v-for="item in inventoryStatusOptions" :key="item.value">{{item.label}}</a-select-option>-->
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" :loading="loading">查询</a-button>
        <a-button
          class="ant-btn ant-btn-primary ant-btn-left"
          disabled="loading"
          @click="handleResetClick"
          >重置</a-button
        >
        <template v-if="tableindex === 1">
          <button
            v-if="selectedRowKeys.length > 0"
            type="button"
            class="ant-btn ant-btn-primary ant-btn-left"
            @click="handleUploadAdd"
          >
            批量添加
          </button>
          <button
            v-else
            disabled
            type="button"
            class="ant-btn ant-btn-normal ant-btn-left"
            @click="handleUploadAdd"
          >
            批量添加
          </button>
        </template>
        <template v-if="tableindex === 2">
          <button
            v-if="selectedNewRowKeys.length > 0"
            type="button"
            class="ant-btn ant-btn-primary ant-btn-left"
            @click="handleUploadDelete"
          >
            批量删除
          </button>
          <button v-else disabled type="button" class="ant-btn ant-btn-normal ant-btn-left">
            批量删除
          </button>
        </template>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { goodsChannelList, sourceOptions, sendSampleTwoOptions } from '@/const/goods-cate.js';
import { supplierList } from '@/service/supplier';
import { getBrands } from '@/service/goods';
import GoodsRange from '../../admin/goods/goods-range';
import { checkNotspecial } from '@/utils/validator';
// 搜索类型
const searchTypeOptions = [
  { label: '商品名称', value: 2 },
  { label: '商品ID', value: 1 },
];

// 库存状态
const inventoryStatusOptions = [
  { label: '上架', value: 1 },
  { label: '下架', value: 2 },
];

// 淘宝联盟状态
const taobaoUnionStatusOptions = [
  { label: '正常', value: 1 },
  { label: '异常', value: 2 },
];

// 支持寄样
const isSupportSampleOptions = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
];

// 支持寄样
const sampleRecycleOptions = [
  { label: '是', value: 1 },
  { label: '否', value: 0 },
];

// 商品来源
const dataSourceOptions = [
  { label: '自动获取', value: 1 },
  { label: '后台维护', value: 2 },
];
const sourceOptionsnew = [
  { label: '招商系统', value: 5 },
  { label: '精选联盟', value: 6 },
  { label: '团长活动', value: 7 },
  // { label: "批量导入", value: 8 },
];

// 时间类型
const timeTypeOptions = [
  { label: '创建时间', value: 1 },
  { label: '上架时间', value: 2 },
  { label: '最后更新', value: 3 },
];

export default {
  name: 'GoodsSearchBar',
  components: { GoodsRange },
  props: {
    searchParams: {
      default: function () {
        return {};
      },
      type: Object,
    },
    goodsCate: {
      type: Array,
      default: () => [],
    },
    loading: {
      default: false,
      type: Boolean,
    },
    tableindex: {
      default: 1,
      type: Number,
    },
    selectedRowKeys: {
      type: Array,
    },
    selectedNewRowKeys: {
      type: Array,
    },
  },
  data() {
    return {
      checkNotspecial,
      form: this.$form.createForm(this),
      searchTypeOptions,
      inventoryStatusOptions,
      taobaoUnionStatusOptions,
      isSupportSampleOptions,
      sampleRecycleOptions,
      dataSourceOptions,
      sourceOptions,
      sourceOptionsnew,
      goodsChannelList,
      timeTypeOptions,
      sendSampleOptions: sendSampleTwoOptions,
      supplierList: [],
      brandList: [],
    };
  },
  methods: {
    handleSearchSupplier(str) {
      supplierList({
        supplier_name: str,
      }).then(res => {
        if (res.res.success === true) {
          this.supplierList = res.res.data.results;
        }
      });
    },
    handleUploadAdd() {
      this.$emit('handleUploadAdd', this.searchParams);
    },
    handleUploadDelete() {
      this.$emit('handleUploadDelete', this.searchParams);
    },
    handleResetClick() {
      // 查询条件
      this.searchParams = {
        search: '',
        type: 2,
        is_on_sale: undefined,
        douyin_status: undefined,
        is_recycle: undefined,
        data_source: undefined,
        source: undefined,
        goods_channel: undefined,
        is_support_new: undefined,
        time_type: undefined,
        start_time: undefined,
        end_time: undefined,
        custom_cat: undefined,
        supplier_obj_id: undefined,
        kuran_price: [],
        external_commission_rate: [],
        campaign_product_service_rate: [],
        item_tag: undefined,
      };
    },
    // 申请列表搜素表单submit
    handleFormSubmit(e) {
      e.preventDefault();
      this.form.validateFields(err => {
        if (!err) {
          this.$emit('callback', this.searchParams);
        }
      });
    },
    // 修改查询时间段
    onChangeRangeTime(e, str) {
      this.searchParams.range_time = str;
      this.searchParams.start_time = str[0];
      this.searchParams.end_time = str[1];
    },
    handleSearchBrand(str) {
      getBrands({
        name: str,
        page: 1,
        page_size: 20,
      }).then(res => {
        if (res.res.success === true) {
          this.brandList = res.res.data.results;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.goods-search-bar {
  margin-bottom: 12px;
}

.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;

  .option-btn {
    margin-right: 10px;
  }
}
.ant-btn-left {
  margin-left: 10px;
}
</style>
