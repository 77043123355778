/*
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-05-15 14:16:48
 * @LastEditTime: 2020-07-18 16:50:51
 */
/* const typeMap = {
  'text': '纯文本输入框',
  'input-number': '纯数字输入框',
  'font': '字体样式',
  'upload': '图片上传控件',
  'select': '下拉选择',
  'radio': 'Radio单选',
  'datetime': '日期时间选择器',
  'color-picker': '颜色拾取器',
  'desc': '描述文本内容'
} */

module.exports = {
  'kuran-paragraph': {
    title: '段落模块',
    domId: '',
    domName: '',
    isBaseShow: false,
    isStyleShow: true,
    isOthersShow: true,
    isActionShow: true,
    // base: [
    //   {
    //     type: 'select',
    //     label: '1标题风格',
    //     attr: 'theme',
    //     val: 'theme-1',
    //     options: [{
    //       name: '风格一',
    //       val: 'theme-1'
    //     }, {
    //       name: '风格二',
    //       val: 'theme-2'
    //     }]
    //   }, {
    //     type: 'text',
    //     label: '标题文案',
    //     val: '标题',
    //     isNecessary: true
    //   }
    // ],
    style: [
      /* {
        type: 'color-picker',
        label: '背景颜色',
        attr: 'background-color',
        val: '#ffffff',
        isNecessary: false
      },*/
      {
        type: 'input-number',
        label: '上边距',
        attr: 'margin-top',
        val: 10,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '右边距',
        attr: 'margin-right',
        val: 8,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '下边距',
        attr: 'margin-bottom',
        val: 10,
        unit: 'px',
        min: 10,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '左边距',
        attr: 'margin-left',
        val: 8,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      // {
      //   type: 'input-number',
      //   label: '外边距',
      //   attr: 'margin',
      //   val: 12,
      //   unit: 'px',
      //   min: 0,
      //   max: 50,
      //   step: 1,
      //   isNecessary: true
      // },
      {
        type: 'input-number',
        label: '内边距',
        attr: 'padding',
        val: 12,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '圆角',
        attr: 'border-radius',
        val: 6,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'color-picker',
        label: '背景颜色',
        attr: 'background-color',
        val: '#ffffff',
        isShow: true,
      },
    ],
    action: {
      type: 'kuran-page-paragraph-list',
      title: '段落项',
      config: [
        {
          errorMsg: {
            content: '请填写段落内容',
          },
          content:
            'H5制作工厂是一个移动端页面设计和制作平台，无需任何开发经营也可以制作自己的移动端站点和页面，从灵感到实现，您只需要10分钟。',
        },
      ],
    },
  },
  'kuran-goods-fullwidth': {
    title: '单个商品',
    domId: '',
    domName: '',
    isBaseShow: true,
    isStyleShow: true,
    isOthersShow: true,
    isActionShow: true,
    base: [
      {
        type: 'radio',
        label: '单排数量',
        attr: 'split',
        val: 1,
        options: [
          {
            name: '1个',
            val: 1,
          },
          {
            name: '2个',
            val: 2,
          },
          {
            name: '3个',
            val: 3,
          },
        ],
        isNecessary: true,
        isShow: true,
      },
    ],
    style: [
      /* {
        type: 'color-picker',
        label: '背景颜色',
        attr: 'background-color',
        val: '#ffffff',
        isNecessary: false
      },*/
      {
        type: 'color-picker',
        label: '标签底色',
        attr: 'background',
        val: '#ffffff',
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'color-picker',
        label: '标签字色',
        attr: 'color',
        val: '#F43324',
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '上外边距',
        attr: 'margin-top',
        val: 10,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '右外边距',
        attr: 'margin-right',
        val: 8,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '下外边距',
        attr: 'margin-bottom',
        val: 10,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '左外边距',
        attr: 'margin-left',
        val: 8,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
    ],
    action: {
      type: 'kuran-goods-fullwidth-click',
      title: '内容配置',
      config: [
        {
          errorMsg: {
            goodsId: '请填写商品id',
            goodsName: '请填写商品名称',
            tag1: '',
            tag2: '',
          },
          goodsId: '',
          goodsName: '',
          tag1: '',
          tag2: '',
          tempGoodsInfo: {},
        },
      ],
    },
  },
  'kuran-form': {
    title: '表单',
    domId: '',
    domName: '',
    assert: {
      // script: ['./js/form/form.min.js'],
      // style: ['./js/form/form.min.css']
    },
    isBaseShow: true,
    isStyleShow: false,
    isOthersShow: true,
    isActionShow: true,
    base: [
      {
        type: 'datetime',
        label: '报名截止',
        attr: 'end-time',
        val: '',
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'text',
        label: '提交地址',
        val: '',
        placeholder: '表单提交的接口地址',
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'radio',
        label: '提交方式',
        val: 'POST',
        options: [
          {
            name: 'GET',
            val: 'GET',
          },
          {
            name: 'POST',
            val: 'POST',
          },
        ],
        isNecessary: true,
        isShow: false,
      },
    ],
    style: [
      {
        type: 'input-number',
        label: '容器高度',
        attr: 'height',
        val: '',
        unit: 'px',
        isNecessary: false,
        isShow: false,
      },
      {
        type: 'color-picker',
        label: '背景颜色',
        attr: 'background-color',
        val: '#ffffff',
        isNecessary: false,
        isShow: false,
      },
      {
        type: 'upload',
        label: '背景图片',
        attr: 'background-image',
        val: '',
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'select',
        label: '背景尺寸',
        attr: 'background-size',
        val: 'contain',
        options: [
          {
            name: '无',
            val: 'inherit',
          },
          {
            name: '宽高裁剪',
            val: 'cover',
          },
          {
            name: '宽高自适应',
            val: 'contain',
          },
        ],
        isShow: false,
      },
      {
        type: 'input-number',
        label: '字体大小',
        attr: 'font-size',
        val: 14,
        unit: 'px',
        step: 1,
        max: 100,
        min: 10,
        isNecessary: false,
        isShow: false,
      },
      {
        type: 'color-picker',
        label: '字体颜色',
        attr: 'color',
        val: '#333333',
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '圆角',
        attr: 'border-radius',
        val: 6,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器上白',
        attr: 'padding-top',
        val: 10,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '容器右白',
        attr: 'padding-right',
        val: 13,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '容器下白',
        attr: 'padding-bottom',
        val: 10,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '容器左白',
        attr: 'padding-left',
        val: 13,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '容器外上',
        attr: 'margin-top',
        val: 10,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外右',
        attr: 'margin-right',
        val: 8,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外下',
        attr: 'margin-bottom',
        val: 10,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外左',
        attr: 'margin-left',
        val: 8,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
    ],
    others: {
      type: 'form-item',
      title: '表单样式',
      config: [
        {
          type: 'input-number',
          label: 'label宽度',
          attr: 'form-label_width',
          val: 70,
          unit: 'px',
          min: 0,
          max: 200,
          step: 1,
          isNecessary: true,
          isShow: true,
        },
        {
          type: 'input-number',
          label: 'input高度',
          attr: 'form-input_height',
          val: 35,
          unit: 'px',
          min: 0,
          max: 150,
          step: 1,
          isNecessary: true,
          isShow: false,
        },
        {
          type: 'input-number',
          label: 'input圆角',
          attr: 'form-input_border-radius',
          val: 4,
          unit: 'px',
          min: 0,
          max: 50,
          step: 1,
          isNecessary: true,
          isShow: false,
        },
        {
          type: 'color-picker',
          label: 'input边框',
          attr: 'form-input_border-color',
          val: '#EFEFEF',
          unit: '',
          isNecessary: true,
          isShow: false,
        },
        {
          type: 'input-number',
          label: 'input间距',
          attr: 'form-item_margin-top',
          val: 10,
          unit: 'px',
          min: 0,
          max: 150,
          step: 1,
          isNecessary: true,
          isShow: false,
        },
        {
          type: 'input-number',
          label: '按钮距上',
          attr: 'form-btn_margin-top',
          val: 15,
          unit: 'px',
          min: 0,
          max: 100,
          step: 1,
          isNecessary: true,
          isShow: false,
        },
        {
          type: 'input-number',
          label: '按钮高度',
          attr: 'form-btn_height',
          val: 38,
          unit: 'px',
          min: 0,
          max: 100,
          step: 2,
          isNecessary: true,
          isShow: false,
        },
        {
          type: 'input-number',
          label: '按钮宽度',
          attr: 'form-btn_width',
          val: 100,
          unit: '%',
          min: 0,
          max: 100,
          step: 1,
          isNecessary: true,
          isShow: false,
        },
        {
          type: 'color-picker',
          label: '按钮背景',
          attr: 'form-btn_background-color',
          val: '#D72E51',
          unit: '',
          isNecessary: true,
          isShow: true,
        },
        {
          type: 'color-picker',
          label: '按钮边框',
          attr: 'form-btn_border-color',
          val: '#D72E51',
          unit: '',
          isNecessary: true,
          isShow: false,
        },
        {
          type: 'input-number',
          label: '按钮圆角',
          attr: 'form-btn_border-radius',
          val: 8,
          unit: 'px',
          min: 0,
          max: 50,
          step: 1,
          isNecessary: true,
          isShow: false,
        },
        {
          type: 'input-number',
          label: '按钮字体',
          attr: 'form-btn_font-size',
          val: 16,
          unit: 'px',
          min: 10,
          max: 100,
          step: 1,
          isNecessary: true,
          isShow: false,
        },
        {
          type: 'color-picker',
          label: '按钮字色',
          attr: 'form-btn_color',
          val: '#fff',
          unit: '',
          isNecessary: true,
          isShow: true,
        },
        {
          type: 'input-number',
          label: '按钮字重',
          attr: 'form-btn_font-weight',
          val: 500,
          unit: '',
          min: 100,
          max: 900,
          step: 100,
          isNecessary: true,
          isShow: false,
        },
      ],
    },
    action: {
      type: 'kuran-form-submit',
      title: '表单项配置',
      config: [
        {
          errorMsg: {
            desc: '请填写粉丝数',
          },
          desc: '粉丝数',
          type: 'text',
          name: 'fans',
          placeholder: '',
          isNecessary: 1,
          isShow: true,
        },
        {
          errorMsg: {
            desc: '请填写报价',
          },
          desc: '报价',
          type: 'text',
          name: 'price',
          placeholder: '',
          isNecessary: 1,
          isShow: true,
        },
        {
          errorMsg: {
            desc: '请填写预计ROI',
          },
          desc: '预计ROI',
          type: 'text',
          name: 'roi',
          placeholder: '',
          isNecessary: 1,
          isShow: true,
        },
      ],
    },
  },
  'kuran-title': {
    title: '标题模块',
    domId: '',
    domName: '',
    isBaseShow: false,
    isStyleShow: true,
    isOthersShow: true,
    isActionShow: true,
    // base: [
    //   {
    //     type: 'text',
    //     label: '标题文案',
    //     val: '标题',
    //     isNecessary: true
    //   }
    // ],
    style: [
      {
        type: 'color-picker',
        label: '字体颜色',
        attr: 'color',
        val: '#D72E51',
        isNecessary: false,
        isShow: true,
      },
    ],
    action: {
      type: 'kuran-title-click',
      title: '标题',
      config: [
        {
          errorMsg: {
            content: '请填写标题文案',
          },
          type: 'none',
          content: '标题文案',
        },
      ],
    },
  },
  'kuran-floor-menu': {
    title: '楼层导航',
    domId: '',
    domName: '',
    isBaseShow: true,
    isStyleShow: true,
    isOthersShow: true,
    isActionShow: true,
    assert: {
      script: ['/assets/js/iscroll.js', '/assets/js/floor-menu.js'],
    },
    base: [
      {
        type: 'radio',
        label: '支持展开',
        val: true,
        options: [
          {
            val: true,
            name: '支持',
          },
          {
            val: false,
            name: '不支持',
          },
        ],
        isShow: true,
      },
    ],
    style: [
      {
        type: 'color-picker',
        label: '默认背景',
        attr: 'background-color',
        val: '#ffffff',
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'color-picker',
        label: '选中背景',
        attr: 'background-color:hover',
        val: '#ffffff',
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'color-picker',
        label: '默认字体',
        attr: 'color',
        val: '#666666',
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'color-picker',
        label: '选中颜色',
        attr: 'color:hover',
        val: '#D72E51',
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '圆角',
        attr: 'border-radius',
        val: 8,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '上边距',
        attr: 'padding-top',
        val: 0,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '右边距',
        attr: 'padding-right',
        val: 8,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '下边距',
        attr: 'padding-bottom',
        val: 0,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '左边距',
        attr: 'padding-left',
        val: 8,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
    ],
    action: {
      type: 'kuran-floor-menu-click',
      title: '导航项配置',
      config: [
        {
          errorMsg: {
            content: '请填写导航项名称',
          },
          type: 'none',
          text: '默认项',
          click: null,
        },
      ],
    },
  },
  'split-image': {
    title: '分割图',
    domId: '',
    domName: '',
    isBaseShow: true,
    isStyleShow: true,
    isOthersShow: true,
    isActionShow: true,
    base: [
      {
        type: 'radio',
        label: '等分数量',
        attr: 'split',
        val: 1,
        options: [
          {
            name: '单图',
            val: 1,
          },
          {
            name: '二等分图',
            val: 2,
          },
          {
            name: '三等分图',
            val: 3,
          },
        ],
        isShow: true,
        isNecessary: true,
      },
    ],
    style: [
      {
        type: 'input-number',
        label: '上边距',
        attr: 'padding-top',
        val: 0,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '右边距',
        attr: 'padding-right',
        val: 0,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '下边距',
        attr: 'padding-bottom',
        val: 0,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '左边距',
        attr: 'padding-left',
        val: 0,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '容器外上',
        attr: 'margin-top',
        val: 10,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外右',
        attr: 'margin-right',
        val: 8,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外下',
        attr: 'margin-bottom',
        val: 10,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外左',
        attr: 'margin-left',
        val: 8,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
    ],
    action: {
      type: 'split-image-click',
      title: '图片项配置',
      width: null,
      height: null,
      config: [
        {
          errorMsg: null,
          width: null,
          height: null,
          imgUrl: '',
          type: 'goods',
          value: '',
        },
      ],
    },
  },
  'kuran-swiper': {
    title: '轮播图',
    domId: '',
    domName: '',
    isBaseShow: false,
    isStyleShow: true,
    isOthersShow: true,
    isActionShow: true,
    // base: [
    // {
    //   type: 'radio',
    //   label: '自动播放',
    //   attr: 'autoplay',
    //   val: true,
    //   options: [{
    //     name: '是',
    //     val: true
    //   }, {
    //     name: '否',
    //     val: false
    //   }],
    //   isNecessary: false
    // }, {
    //   type: 'radio',
    //   label: '显示分页',
    //   attr: 'pagination',
    //   val: true,
    //   options: [{
    //     name: '是',
    //     val: true
    //   }, {
    //     name: '否',
    //     val: false
    //   }],
    //   isNecessary: false
    // }],
    style: [
      // {
      //   type: 'color-picker',
      //   label: '背景颜色',
      //   attr: 'background-color',
      //   val: 'rgba(255, 255, 255, 0)',
      //   isNecessary: true
      // },
      {
        type: 'input-number',
        label: '上边距',
        attr: 'padding-top',
        val: 0,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '右边距',
        attr: 'padding-right',
        val: 0,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '下边距',
        attr: 'padding-bottom',
        val: 0,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '左边距',
        attr: 'padding-left',
        val: 0,
        unit: 'px',
        min: 0,
        max: 50,
        step: 1,
        isNecessary: true,
        isShow: false,
      },
      {
        type: 'input-number',
        label: '容器外上',
        attr: 'margin-top',
        val: 10,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外右',
        attr: 'margin-right',
        val: 8,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外下',
        attr: 'margin-bottom',
        val: 10,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外左',
        attr: 'margin-left',
        val: 8,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
    ],
    action: {
      type: 'kuran-swiper-list',
      title: '图片项配置',
      width: null,
      height: null,
      config: [
        {
          errorMsg: {
            imgUrl: '请上传图片',
            jumpValue: '请填写商品ID',
          },
          width: null,
          height: null,
          imgUrl: '',
          type: 'goods',
          value: '',
        },
      ],
    },
  },
  'king-kong': {
    title: '金刚位',
    domId: '',
    domName: '',
    active: false,
    isBaseShow: true,
    isStyleShow: true,
    isOthersShow: true,
    isActionShow: true,
    base: [
      {
        type: 'radio',
        label: '单排个数',
        attr: 'split',
        val: 4,
        options: [
          {
            name: '4个',
            val: 4,
          },
          {
            name: '5个',
            val: 5,
          },
        ],
        isNecessary: true,
        isShow: true,
      },
    ],
    style: [
      {
        type: 'color-picker',
        label: '字体颜色',
        attr: 'color',
        val: 'rgba(50, 50, 50, 1)',
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外上',
        attr: 'margin-top',
        val: 10,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外右',
        attr: 'margin-right',
        val: 8,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外下',
        attr: 'margin-bottom',
        val: 10,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外左',
        attr: 'margin-left',
        val: 8,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
    ],
    action: {
      type: 'king-kong-click',
      title: '内容配置',
      width: null,
      height: null,
      config: [
        {
          width: null,
          height: null,
          imgUrl: '',
          label: '',
          type: 'none',
          value: '',
        },
      ],
    },
  },
  'v-goods-bang': {
    title: '外露榜单',
    domId: '',
    domName: '',
    active: false,
    isBaseShow: true,
    isStyleShow: true,
    isOthersShow: true,
    isActionShow: true,
    // style: [{
    //   type: 'color-picker',
    //   label: '字体颜色',
    //   attr: 'background-color',
    //   val: 'rgba(50, 50, 50, 1)',
    //   isNecessary: true
    // }],
    style: [
      {
        type: 'color-picker',
        label: '标题字色',
        attr: 'color',
        val: '#333333',
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'color-picker',
        label: '描述颜色',
        attr: 'color',
        val: '#999999',
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外上',
        attr: 'margin-top',
        val: 10,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外右',
        attr: 'margin-right',
        val: 8,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外下',
        attr: 'margin-bottom',
        val: 10,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'input-number',
        label: '容器外左',
        attr: 'margin-left',
        val: 8,
        unit: 'px',
        min: 0,
        max: 100,
        step: 1,
        isNecessary: true,
        isShow: true,
      },
    ],
    base: [
      {
        type: 'text',
        label: '标题文案',
        val: '',
        placeholder: '请输入标题',
        isNecessary: true,
        maxLength: 16,
        isShow: true,
      },
      {
        type: 'text',
        label: '描述文案',
        val: '',
        maxLength: 22,
        placeholder: '请输入描述文案',
        isNecessary: true,
        isShow: true,
      },
      {
        type: 'image-uploader',
        label: '背景图',
        val: '',
        imgUrl: 'https://oss.zheyi360.com/app-image/topic/images/default_v-goods-bang.png',
        placeholder: '',
        isNecessary: true,
        isShow: true,
      },
      {
        isJumpMod: true,
        label: '跳转地址',
        type: 'module',
        value: 'sample',
        jumpTypes: ['module', 'showtime', 'link'],
        isNecessary: true,
        isShow: true,
      },
    ],
    action: {
      type: 'v-goods-bang-click',
      title: '内容配置',
      config: [
        {
          goodsId: null,
          tempGoodsData: {
            item_id__white_image: '',
          },
        },
        {
          goodsId: null,
          tempGoodsData: {
            item_id__white_image: '',
          },
        },
        {
          goodsId: null,
          tempGoodsData: {
            item_id__white_image: '',
          },
        },
        {
          goodsId: null,
          tempGoodsData: {
            item_id__white_image: '',
          },
        },
      ],
    },
  },
};
