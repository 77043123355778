/*
 * @Description: file content
 * @Author: 前胡
 * @LastEditors: 白青
 * @Date: 2020-05-16 14:04:14
 * @LastEditTime: 2020-05-26 17:40:37
 */
module.exports = {
  title: '页面配置',
  base: [
    {
      type: 'text',
      label: '页面标题',
      attr: 'page-name',
      placeholder: '例：双十一专题主页',
      val: '',
      isNecessary: true,
      maxLength: 10,
    },
    {
      type: 'text',
      label: '专题标签',
      attr: 'page-tag',
      placeholder: '(选填) 限制2-6字数',
      val: '',
      isNecessary: false,
      maxLength: 6,
    },
    {
      type: 'color-picker',
      label: '背景颜色',
      attr: 'background-color',
      val: '#ffffff',
    },
  ],
  // weixin: [
  //   {
  //     type: 'text',
  //     label: 'jssdk api',
  //     attr: 'page-share-api',
  //     placeholder: '微信分享接口地址',
  //     val: '',
  //     isNecessary: true
  //   }, {
  //     type: 'text',
  //     label: '跳转地址',
  //     attr: 'page-share-url',
  //     placeholder: '跳转地址，不填则代表当前页',
  //     val: '',
  //     isNecessary: false
  //   }, {
  //     type: 'text',
  //     label: '分享标题',
  //     attr: 'page-share-title',
  //     placeholder: '微信分享的标题',
  //     val: '',
  //     isNecessary: true
  //   }, {
  //     type: 'textarea',
  //     label: '分享描述',
  //     attr: 'page-share-desc',
  //     placeholder: '微信分享的描述文字',
  //     val: '',
  //     isNecessary: true
  //   }, {
  //     type: 'upload',
  //     label: '分享图标',
  //     attr: 'page-share-icon',
  //     val: '',
  //     isNecessary: true
  //   }, {
  //     type: 'desc',
  //     label: '使用说明',
  //     val: `获取微信签名的接口请使用get(application/json)方式，跨域需接口支持，返回格式如下：<br/>
  //          {
  //             data: {
  //               appId: 'appId',
  //               timestamp: 15888888,
  //               nonceStr: 'nonceStr',
  //               signature: 'signature',
  //             },
  //             msg: '不为空则提示该消息'
  //          }`
  //   }
  // ]
};
