import { Loading } from 'element-ui';

let loadingCount = 0;
let loading;

const startLoading = str => {
  loading = Loading.service({
    lock: true,
    text: str,
    background: 'rgba(0, 0, 0, 0.5)',
  });
};

const endLoading = () => {
  loading.close();
};

export const showLoading = (str = '加载中……') => {
  if (loadingCount === 0) {
    startLoading(str);
  }
  loadingCount += 1;
};

export const hideLoading = () => {
  if (loadingCount <= 0) {
    return;
  }
  loadingCount -= 1;
  if (loadingCount === 0) {
    endLoading();
  }
};
